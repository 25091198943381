@import './variables.scss';
/*
    *
    *   HOME SCREEN LOADING
    *
*/

.homeLoading {
  position: relative;
  z-index: 9999999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  height: calc(99vh - 50px);
  width: 100%;
  display: flex;
  flex-direction: column;
  .heading {
    z-index: 9999999;
    height: auto;
    width: 100%;
    overflow: visible;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    font-family: SF Pro Display;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 88.4%;
    text-align: center;
    color: $black-blue;
    display: flex;
    align-items: center;
    justify-content: center;
    order: 2;
    margin: 0;

    &:before {
      content: "";
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // background-color: rgba(0, 0, 0, 0.3);
    }
    &:not(:required):after {
      content: "";
      display: block;
      font-size: 10px;
      width: 1em;
      height: 1em;
      margin-top: -0.5em;
    }
  }
}


.animation-loader {
	height: 90vh;
	display: flex;
	align-items: center;
	justify-content: center;

	.loader-animation-container {
		max-width: 500px;
		text-align: center;

		.circle {
			margin: auto;
			position: relative;
			width: 140px;
			height: 140px;
			border-radius: 100vmax;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.logo {
			position: absolute;
			width: 38px;
			height: 38px;
			background: url(/assets/images/logo/bsecure_favicon.png);
			background-size: cover;
			// background-position: center;
		}

		.text-animated {
			position: absolute;
			width: 100%;
			height: 100%;
			color: RGB(var(--theme-custom-color));
			font-size: 16px;
			animation: textRotation 10s linear infinite;
		}

		@keyframes textRotation {
			to {
				transform: rotate(360deg);
			}
		}

		.text-animated span {
			position: absolute;
			left: 50%;
			font-weight: 500;
			font-size: 20px;
			transform-origin: 0 70px;
		}

		.heading-main {
			padding-top: 2rem;
			.heading {
				font-weight: 700;
				font-size: 24px;
				line-height: 0.8rem;
			}
			span {
				color: RGB(var(--theme-custom-color));
			}
			.sub-heading {
				font-weight: 400;
				font-size: 15px;
				color: $mid-grey;
			}
		}

		.points-container {
			margin-top: 2rem;
			@media screen and (max-width: 400px) {
				margin-left: 1rem;
			}
			.point {
				margin: 1.5rem 0;
				display: flex;
				align-items: center;
				gap: 1.3rem;
				span {
					font-weight: 500;
					font-size: 18px;
				}

				.img-container {
					width: 40px;
					height: 40px;
					background: #ebf5ff;
					box-shadow: 0px 3.04106px 7.60264px rgba(60, 66, 87, 0.08),
						0px 0px 0px 1.52053px rgba(60, 66, 87, 0.16),
						0px 1.52053px 1.52053px rgba(0, 0, 0, 0.12);
					border-radius: 200px;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
		}

		button {
			width: 95%;
			margin: 1rem auto 0;
      padding: 7px;
		}
	}
}
