body[dir="rtl"] {

    html,
    body,
    div,
    span,
    applet,
    object,
    iframe,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    blockquote,
    pre,
    a,
    abbr,
    acronym,
    address,
    big,
    cite,
    code,
    del,
    dfn,
    em,
    img,
    ins,
    kbd,
    q,
    s,
    samp,
    small,
    strike,
    strong,
    sub,
    sup,
    tt,
    var,
    b,
    u,
    center,
    dl,
    dt,
    dd,
    ol,
    ul,
    li,
    fieldset,
    form,
    label,
    legend,
    table,
    caption,
    tbody,
    tfoot,
    thead,
    tr,
    th,
    td,
    article,
    aside,
    canvas,
    details,
    embed,
    figure,
    figcaption,
    footer,
    header,
    hgroup,
    menu,
    nav,
    output,
    ruby,
    section,
    summary,
    time,
    mark,
    audio,
    video,
    article,
    aside,
    details,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    menu,
    nav,
    section,
    ol,
    ul,
    blockquote,
    q,
    table,
    .btn-primary,
    .btn-outline-primary,
    .btn-outline-white,
    .stepNo,
    .react-custom-flag-select__select__wrapper___1R93I,
    .select-text,
    .accEditSave,
    .myForm,
    input,
    .MuiInputBase-input,
    .MuiTypography-body1,
    .MuiFormControlLabel-label,
    .bSecureIcon,
    .statusText,
    .ContNum,
    .totalPrice,
    .MuiTypography-h6,
    .MuiButton-root {
        font-family: $JameelNoori !important;
        // font-family: $Scheherazade !important;
    }

    .myForm,
    #not-as-user,
    #continue-as-user,
    .totalPrice,
    .signviewsmalltext {
        direction: rtl;
        word-spacing: 0.15rem;
    }

    .myForm {
        &.addressForm{
            .MuiAutocomplete-root{
                text-align: right;
                direction: rtl;
            }
        }
    }

    #privacyDialogContent {
        text-align: right;
        direction: rtl;
    }

    .errorMessage {
        direction: rtl;
        text-align: right;
        float: right;
    }

    #phone_number {
        font-size: 20px !important;
    }

    .MuiTypography-gutterBottom {
        margin-bottom: 1.85em;
    }

    .MuiButtonBase-root.MuiButton-root.MuiButton-text.btn.btn-white.btn-white-B.MuiButton-textPrimary {
        margin-left: 10px;
    }

    header {
        // font-family: $Scheherazade !important;
        font-family: $JameelNoori !important;

        span {
            // font-family: $Scheherazade !important;
            font-family: $JameelNoori !important;
        }

        .languageIcon {
            .nav-link {
                margin: -3px 0 0 0;
            }
        }
    }

    .signInView {
        .Otptext {
            font-size: 16px;
            line-height: 24px;
        }

        h1 {
            // font-family: $Scheherazade !important;
            font-family: $JameelNoori !important;
            font-size: 42px;
        }

        .signviewsmalltext {
            font-size: 20px;
        }
    }

    .statusText {
        // font-family: $Scheherazade !important;
        font-family: $JameelNoori !important;
    }

    // [class^="icon-"],
    // [class*=" icon-"] {
    //     font-family: 'icomoon' !important;
    // }


    [class^="new-icon-"],
    [class*=" new-icon-"] {
        /* use !important to prevent issues with browser extensions that change fonts */
        font-family: 'new-icomoon' !important;

    }


    [class^="new-icons-"],
    [class*=" new-icons-"] {
        /* use !important to prevent issues with browser extensions that change fonts */
        font-family: 'new-icomoon-file' !important;

    }

    [class^="icomoon-"],
    [class*=" icomoon-"] {
         /* use !important to prevent issues with browser extensions that change fonts */
        font-family: 'icomoon' !important;
    }

    [class^="new-icons-"],
    [class*=" new-icons-"] {
        /* use !important to prevent issues with browser extensions that change fonts */
        font-family: 'new-icon' !important;

    }


    [class^="icomoon-"],
    [class*=" icomoon-"] {
        /* use !important to prevent issues with browser extensions that change fonts */
        font-family: 'icomoon' !important;

    }

    input {
        text-align: right;
    }

    .alert_classes{
        direction: rtl;
    }

    .OtpNum {
        direction: ltr;
        .OTPPin {
            .OTPPinNo {
                text-align: left;
                left: 0;
            }
        }
    }

    .customRadio .accordion .card .card-header h5 {
        font-size: 17px;
        line-height: 20px;
        // height: 26px;
        word-spacing: 2px;
        display: flex;
        align-items: center;
        word-spacing: 2px;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: 0px;
        direction: rtl;
        padding-right: 3px;
        line-height: 1.8;
    }

    .MuiInputLabel-outlined {
        &.MuiInputLabel-shrink {
            transform: translate(-12px, -7px) scale(0.95);
        }
    }

    /*.PrivateNotchedOutline-legendLabelled-3
    {
        text-align: right;
    }
    .MuiInputLabel-formControl
    {
        text-align: right;
    }
    .MuiInputBase-input
    {
        text-align: right;
        &:-moz-placeholder {
            text-align: right;
        }
        &:-ms-input-placeholder {
            text-align: right;
        }
        &::-webkit-input-placeholder {
            text-align: right;
        }
    }*/
    .browSetting {
        direction: rtl;
        text-align: right;
        font-size: 19px;
        line-height: 26px;
        word-spacing: 2px;
        ul {
            direction: rtl;
            text-align: right;
        }
    }

    .headerDropdown {
        .name-full {
            font-size: 15px;
            
        }

    }


    .direct-bank-transfer {

        .dbt-explanation {
            text-align: right;
            font-size: 16px;
            line-height: 1.35rem;
        }

        .dbt-message {
            text-align: right;
            font-size: 16px;
            line-height: 1.55rem;
        }

        .dbt-details {
            text-align: right;

            .dbt-title {
                font-size: 16px;
                // display: grid;

                .dbt-label {
                    font-size: 16px;
                    // display: flex;
                }
            }
        }
    }

    /*
        *
        *   PAYMENT METHOD
        *
    */

    .paymentMethod{
        .easyPaisa{
                .easyPaisaSuggestionList {
                    text-align: right;
                    font-size: 19px;
                }

                .numberList {
                    text-align: right;

                    span {
                        text-align: right;
                        font-size: 19px;
                    }
                }
        }
    }

    .checkBoxCard {
        margin-left: auto;
        text-align: right;

        label {
            margin-right: 0;
            direction: rtl;

            span {
                font-size: 19px !important;

                &:first-child {
                    order: 1;
                    padding-right: 0;
                }

                &:last-child {
                    order: 2;
                    text-align: right;
                }
            }
        }
    }


    .MuiInputBase-root {
        // font-size: 1.25rem;
    }

    .MuiInputBase-input {
        &:-moz-placeholder {
            font-size: 20px;
            word-spacing: 2px;
        }

        &:-ms-input-placeholder {
            font-size: 18px;
            word-spacing: 2px;
        }

        &::-webkit-input-placeholder {
            font-size: 18px;
            word-spacing: 2px;
        }
    }

    .MuiFormLabel-root {
        &.Mui-focused {
            transform: translate(-11px, -10px) scale(0.75);
            font-size: 18px;
        }
    }

    .MuiFormHelperText-root {
        font-size: 1rem;
    }

    .receiptSection {
        .receiptInfo {
            word-spacing: 5px;
            font-size: 20px;

            .receiptSub {
                .right {
                    word-spacing: 5px;
                    direction: rtl;
                    width: 56%;
                }
            }
        }
    }

    .transactionSlipMainWrapper {
        .transactionSlipDetails {
            text-align: right;

            .amountPay {
                h4 {
                    font-size: 20px;
                }

                h2 {
                    direction: rtl;
                    font-size: 34px;
                }
            }

            .remainingTime {
                h4 {
                    font-size: 20px;
                }

                h5 {
                    font-size: 20px;
                }
            }

            .transaction-detail {
                h4 {
                    font-size: 20px;
                }

                h5 {
                    font-size: 15.9855px;
                }
            }

            p {
                font-size: 14px;

                span {
                    line-height: 0;
                    margin-left: 10px;
                    font-size: 20px;

                    .MuiButtonBase-root {
                        float: right;
                        padding: 0px;
                    }
                }
            }
        }

        .transactionSlipTopHeader {
            h5 {
                font-size: 28px;
            }

            .screen-capture {
                span {
                    .saveText {

                        font-size: 18px
                    }
                }
            }
        }
    }

    .bottom-padding {
        padding-bottom: 180px;
    }

    .termCondition {
        direction: rtl;
        text-align: right;
        // font-size: 1rem;
        word-spacing: 2px;
        font-size: 1rem;
        font-weight: 500;
        margin: 0px 0px 50px 0px;
        .terms{
            border-left: unset;
            margin-left: unset;
            padding-left: unset;;
            border-right: solid 1px RGB(var(--theme-custom-color));
            margin-right: 0.25rem;
            padding-right: 1rem;
            text-align: right;
        }
        button {
            span{
                word-spacing: 2px;
                font-size: 1rem;
                font-weight: 500;
            }
        }
    }

    .cartItemSection {
        .cartItemBox {
            .cartInfo {
                .cartProductName {
                    p {
                        font-size: 15px !important;
                    }
                }

                .cartInfoBottom {
                    .qty {
                        font-size: 17px;
                    }

                    .price {
                        direction: rtl;
                        font-size: 17px;
                        word-spacing: 4px;
                    }
                }
            }
        }

    }

    .shipmentMethod {
        .shipmentMethodHeader {
            .shipmentHeaderBody {
                    .icon {
                        height: unset;
                        margin-right: unset;
                        margin-left: 8px;
                        // margin-left: 17px;
                        // margin-right: 12px;
                    }
                    .information{
                        text-align: right;
                        border-left: unset !important;
                        border-right: solid 1px #B3B3B3;
                        &.active{
                            border-left: unset !important;
                            border-right: solid 1px rgb(var(--theme-custom-color)) !important;
                        }
                        .overflowText {
                            line-height: 30px;
                            font-size: 1.05rem;
                            // margin-right: 15px;
                            margin-right: 10px;
                        }
                    }
            }
        }

        .shipmentMethodBody{
            .description {
                display: flex;
                padding: 0 !important;
                .shipmentPrice {
                    width: 90px !important;
                    font-size: 15px;
                    text-align: right;
                    word-spacing: 2px;
                    border-left: 1px solid RGB(var(--theme-custom-color));
                    border-right: unset;
                }
                .shipmentDesc {
                    font-size: 15px;
                    word-spacing: 3px;
                    text-align: right;
                }
            }
        }
    }

    .checkOutInfo {
        padding: 0px 0px 7px 0;
        // direction: rtl;
        .checkOutInfoBox {
            margin-bottom: 10px;
            text-align: right;
            .iconCheckout {
                order: 2;
                text-align: right;
                width: 35px;

                .icomoon-review-address {
                    font-size: 25px;
                }

                .icon-ship-custom {
                    font-size: 25px;
                }

                .icomoon-review-payment {
                    font-size: 20px;
                }
            }

            .checkOutDetail {
                order: 1;
                text-align: right;
                font-size: 24px;

                .top {
                    .head {
                        direction: rtl;
                        order: 2;
                        text-align: right;
                    }

                    .edit {

                        font-size: 19px;
                    }
                }

                .main {
                    text-align: right;
                    .subhead-outline{
                        .companyName{
                            display: flex;
                            justify-content: flex-end;
                        }
                        .subhead{
                            float: initial;
                            .information {
                                margin-right:0px;
                                width:85%;
                            }
                        }
                    }
                    
                    .subhead {
                        float: right;
                        // font-size: 15px;
                        word-spacing: 2px;
                        font-size: 16px;

                        .icon {
                            order: 2;
                            text-align: right;
                        }

                        .information {
                            // font-size: 15px;
                            // text-align: right;
                            font-size: 16px;
                            direction: rtl;
                            margin-right: 0.5rem;
                            word-spacing: 4px;
                            text-align: right;
                            order: 1;
                        }

                        .childhead {
                            font-size: 15px;
                            display: flex;
                            justify-content: space-between;
                            align-items: flex-start;
                        }
                    }
                }
            }
        }
    }

    #outlined-error-helper-text-helper-text {
        text-align: right;
    }

    .receiptSub {
        span.left {
            direction: rtl;
            order: 2;
        }
    }

    .receiptSubItems {
        span {
            &.left {
                direction: rtl;
                order: 2;
            }

            &.right {
                direction: rtl;
            }
        }


    }

    .receiptTotal {
        span.total {
            direction: rtl;
            order: 2;
        }
    }

    .editMedia {
        .trashIcon {
            margin-top: 10px;
            margin-right: -30px;
            width: 36px;
            margin-left: 15px;
            order: 2;
        }

        .media-body {
            text-align: right;

            &.address-body {
                text-align: left !important;

                .address-detail {
                    text-align: left;
                    word-spacing: 1px !important;
                    direction: ltr !important;
                    padding-right: 0px !important;
                }
            }
        }

    }

    .paymentMethodHeader {
            .paymentMethodHeaderBody {
                text-align: right;
                direction: rtl;
                .image {
                    order: 2;
                }

                .information {
                    text-align: right !important;
                    margin-right: 10px !important;
                    order: 1;
                    margin-left: unset !important;

                    .overflowText {
                        margin-top: -4px !important;
                        margin-bottom: 5px !important;
                        h5{
                            font-size: 1.25rem;
                        }
                        h6{
                            margin-top: 0px !important;
                        }
                    }

                }
            }
    }

    .processingRadio {
        .accordion {
            .ep-card-header-left {
                min-height: 135px !important;
            }

        }
    }

    .ep-card-detail {
        text-align: right;

        .information {
            width: 220px !important;
            text-align: right !important;
            margin-right: 10px !important;
            order: 1;
            margin-left: unset !important;

            .overflowText {
                font-size: 1.25rem;
            }

            span {
                font-size: 16px !important;
            }
        }
    }


    .twoField .MuiInputBase-input {
        padding: 18.5px 18.5px 17px 0px !important;
    }

    .twoField .MuiGrid-container .MuiInputLabel-formControl {
        transform: translate(0, 20px) scale(1) !important;
        padding-right: 18.5px;
    }

    .cvv {
        .MuiOutlinedInput-root {
            direction: rtl;

            .MuiInputAdornment-root {
                margin-left: 10px;
            }

            .MuiInputBase-input {
                order: 2;
                padding-right: 0 !important;
            }
        }
    }


    .dialogPopup {
        .langSuggestion {
            font-size: 22px;
            line-height: 27px;
            text-align: right;
        }
        .expired {
            font-size: 22px;
            line-height: 27px;
            text-align: right;
        }

        .dialogPopupInv {
            .dropdownAccordion {
                .customRadio {
                    direction: rtl;

                    .card {
                        padding-left: 10px;

                        .card-header {
                            .LangName {
                                font-size: 18px;
                                text-align: right;
                            }

                            .LangCountry {
                                font-size: 13px;
                                text-align: right;
                            }
                        }
                    }
                }
            }
        }
    }

    .processingCardRadio {
        .accordion {
            .card {
                direction: rtl
            }
        }
    }

    .shippingCustomRadio {
        .accordion {
            .card {
                direction: rtl
            }
        }
        .shipmentDisabled {
            .shipmentMethodHeader .shipmentHeaderBody .information {
                border-left: unset !important;
                border-right: solid 1px #FE8083 !important;
            }
        }
    }

    .customGmapWrapper {
        max-width: 411px;
        margin: 24px auto 0;
    }

    .FullScreenGmapWrapper {
        max-width: 1411px;
        // #ribbionParent {
        //     .mapRibbion {
        //         p {
        //             font-size: 19px;
        //             word-spacing: 3px;
        //             margin: 0px 7px;
        //             text-align: right;
        //         }
        //     }
        // }
    }

    .fullscreen-control {
        left: 0px;
        top: 10px !important;

        .fullscreen-location-pin-control {
            top: 10px !important;
        }
    }

    #ribbionParent {
        z-index: 10000001 !important;
        width: 100% !important;
        height: 45px !important;
        background: rgba(235, 245, 255, 1);
        z-index: 9999;
        display: flex;
        flex-direction: row;
        backdrop-filter: blur(157.781px);
        bottom: 0 !important;

        .mapRibbion {
            p {
                line-height: 45px;
                font-size: 19px;
                word-spacing: 3px;
                margin: 0px 7px;
                text-align: right;
            }
        }

        .mappinbtn {
            margin-left: unset;
        }
    }

    #mandatoryPin {
        min-height: 30px !important;

        .pinStripe {
            p {
                word-spacing: 0.2rem;
                font-size: 20px;
                text-align: right;
                line-height: 1.4rem;
            }
        }
    }

    .shippingInfoBoxUr {
        .checkOutDetail {
            .top {
                // justify-content: flex-end;
                align-items: flex-end;
            }
        }
    }

    .totalFixed .receiptTotal {
        align-items: center;

        .total {
            font-size: 28px;
        }
    }
    .footerReviewFixed{
        &.totalFixed{
            .footerReview{
                margin: 10px -10px 15px 0px !important;
            }
        }
        .subFooterReviewText {
            text-align: right !important;
            line-height: 1.45rem;
        }
        .footerReview{
            direction: rtl;
            text-align: right !important;
            margin-right: 15px;
            margin-top: 15px;
            span{
                font-size: 18px !important;
                word-spacing: 3px;
            }
        }
    }
    .totalFixed {
        min-height: 136px;
        height: auto;

        .btn-outline-primary span {
            font-size: 22px;
        }

        .clickedBtn {
            margin-bottom: 5px;
        }
    }

    .react-tel-input {
        input {
            text-align: left !important;
        }

        .form-control {
            &::-webkit-input-placeholder {
                /* Chrome/Opera/Safari */
                text-align: left !important;

            }

            &::-moz-placeholder {
                /* Firefox 19+ */
                text-align: left !important;

            }

            &:-ms-input-placeholder {
                /* IE 10+ */
                text-align: left !important;

            }

            &:-moz-placeholder {
                /* Firefox 18- */
                text-align: left !important;

            }
        }
    }


    .dropInput .input-group {
        .form-control {
            &::-webkit-input-placeholder {
                /* Chrome/Opera/Safari */
                text-align: right;
            }

            &::-moz-placeholder {
                /* Firefox 19+ */
                text-align: right;
            }

            &:-ms-input-placeholder {
                /* IE 10+ */
                text-align: right;
            }

            &:-moz-placeholder {
                /* Firefox 18- */
                text-align: right;
            }
        }
    }


    .stepStatus {
        /*height: 65px;*/

        .activeText {
            .statusText {
                font-size: 20px;
            }
        }

        .statusText {
            font-size: 20px;
            // font-size: 30px;
        }

    }

    @media only screen and (max-width: 410px) {
        .customRadio {
            .accordion {
                .card {
                    .card-header {
                        h5 {
                            // font-size: 19px;
                            font-size: 16px;
                            line-height: 20px;
                            //height: 26px;
                            word-spacing: 2px;
                            line-height: 1.8;
                        }
                    }
                }
            }
            &.paymentRadio{
                .accordion .card .card-header h5 {
                    margin-bottom: 0 !important;
                }
            }
        }

        .processingRadio .accordion .card .card-header h5 {
            font-size: 22px;
            line-height: 26px;
            height: 26px;
        }
    }
    
    .totalPrice {
        word-spacing: 7px;
    }


    .receiptSection {
        .receiptDiscounts {
            .receiptSub {
                .left {
                    width: 70%;
                    text-align: right;

                    div {
                        p {
                            margin-left: 5px;
                            margin-right: 0;
                            width: 100%;

                            span {
                                text-align: left;
                                margin-left: 3px;
                                line-height: 1rem;

                                &.discount-tag {
                                    direction: rtl;
                                    // margin: 4px 0 0 5px !important;
                                    margin-top: 2px !important;
                                }

                                &.close-discount {
                                    svg {
                                        top: 8px !important;
                                    }
                                }
                            }
                        }

                        .discount-pricing {
                            padding-left: 0;
                            padding-right: 0.5rem;
                        }
                    }
                }

                .right {
                    direction: rtl;
                    margin-left: -4px;
                    text-align: left;
                }
            }
        }
    }


    .receiptDiscountBox .bigReceiptHeadingBox {
        .left {
            text-align: right;
        }

        .right {
            direction: rtl;
            text-align: left !important;
        }
    }

    .FullScreenAddressWrapper {
        right: 10px !important;
        left: unset !important;

        .address-pin-container {
            right: 10px;
            direction: ltr;
            // direction: rtl;
            left: unset;
            margin: 10px 0px -61px 10px;
            position: relative;
            display: flex;
        }
        .map-box{
            flex-direction: row-reverse;
            .formRightSide {
                flex-direction: row-reverse;
                button {
                    &:first-child {
                        margin: 0 0px 0px 2px;
                    }
                    &:last-child {
                        padding-left: 0;
                        padding-right: 7px;
                        &:before {
                            right: 0;
                            left: auto;
                        }
                    }
                }
            }

        }
    }

    .headerDropdown {
        .nav-link {
            margin-top: 6px;
        }
    }

    .voucher {
        direction: rtl;

        .MuiInputBase-fullWidth {
            direction: ltr;
            padding-left: 10px;
        }

        .voucher_code {
            .vouchAdornment {
                direction: rtl;
                left: 0;
                font-weight: 400;
                word-spacing: 0.25rem;

                .rtl-position {
                    left: 0;
                    font-weight: 400;
                }
            }
        }
    }

    .bankAccTextField {
        direction: rtl;
    }

    .receiptSection {
        .topPattern {
            top: -17px;
        }

        .bottomPattern {
            bottom: -17px;
        }
    }

    .MuiDialog-paperWidthSm {
        &.TermsPopup, .PrivacyPopup{
            text-align: right;
            direction: rtl;
            .MuiTypography-gutterBottom {
                margin-bottom: 0.5em;
            }
        }
    }

    .footerWrap {
        padding: 10px 0 0 0;

        .container {
            .footer-main {
                direction: rtl;

                ul {
                    margin: 0 10px 0 0px;
                    padding: 0 10px 0 0px;
                    border-right: 1px solid $light-grey;
                    border-left: 0;

                    li {
                        span {
                          margin-right: 0px;
                          margin-left: 19px;
                          font-size: 1rem;
                        }
                        a {
                            margin-right: 0px;
                            margin-left: 19px;
                            font-size: 1rem;
                        }

                        @media only screen and (max-width: 480px) {
                            span {
                                font-size: 0.85rem;
                            }
                        }

                        &:last-child {
                            span {
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .errorPage {
        .errorPageText {
            direction: rtl;
            text-align: right;
        }
    }

    .cartSlideWrapper {
        .cartSlide {
            .insideCartSlideWrap {
                .billingDetailsWrap {

                    .receiptSection {
                        .receiptDiscounts .receiptSub .left div {
                            p {
                                margin-left: 5px;
                                margin-right: 0;
                                width: 58%;
                            }

                            .discount-pricing {

                                padding-top: 0rem;
                                line-height: 81.4%;
                            }
                        }

                        .receiptInfo {
                            font-size: 20px;

                            .right {
                                text-align: inherit !important;
                                direction: rtl;
                                margin: 0px !important;
                                word-spacing: 5px
                            }
                        }

                        .bigReceiptHeadingBox {
                            .right {
                                text-align: right;
                                direction: rtl;
                                word-spacing: 4px;
                            }
                        }

                        .insidesubTotalBox {
                            text-align: right;
                            direction: rtl;
                            .right{
                                word-spacing: 3px;
                            }
                        }
                    }
                }

                .cartTopButtons {
                    button.generalCartSlideBtn {
                        margin: 0 0 0 4%;

                        &:last-child {
                            margin: 0;
                        }

                        span {
                            padding-right: 8px;
                            padding-left: 0;
                        }
                    }
                }

                .cartProductsWrap {
                    .cartPrdLeft {
                        padding-left: 18px;
                        padding-right: 0;

                        .slideProdPrice {
                            text-align: right;
                            direction: "rtl";
                            display: flex;
                            &.suggestPrice{
                                margin-bottom: 5px;
                            }
                            span {
                                order: 1;

                                &:first-child {
                                    order: 2;
                                }
                            }
                        }

                        .cartActionsWrap {
                            .prodQtyWrap {
                                button {
                                    border-radius: 0 2.74211px 2.74211px 0;
                                    margin-left: 13px;
                                    margin-right: 0;

                                    &:last-child {
                                        border-radius: 2.74211px 0px 0px 2.74211px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .cartSlideTotal {
                span.cartSlideTotalAmount {
                    direction: rtl;

                    span {
                        order: 2;
                    }
                }
            }
        }

        .nestedAccordion {
            .billingDetailsSlide {
                font-size: 19.7222px;
                direction: rtl;
                text-align: right;

                i {
                    &.new-icon-billing {}

                    &.icomoon-navigate-bottom {
                        margin-right: auto;
                        margin-left: unset;
                    }
                }
            }
        }
    }

    .cartSlideWrapper {
        .cartSlide .insideCartSlideWrap .cartTopButtons {
            direction: rtl;
        }

        .cartSlide .insideCartSlideWrap .cartProductsWrap {
            direction: rtl;
        }

        .cartSlide .insideCartSlideWrap .cartProductsWrap .cartPrdLeft .cartActionsWrap .prodQtyWrap button:last-child {
            margin: 0 !important;
        }

        .cartSlide .cartSlideTotal {
            direction: rtl;
        }
    }

    .web-sidebar {
        .cartSlideWrapper .cartSlide .insideCartSlideWrap .cartTopButtons {
            direction: rtl;
        }

        .cartSlideWrapper .cartSlide .insideCartSlideWrap .cartProductsWrap {
            direction: rtl;
        }

        .cartSlideWrapper .cartSlide .insideCartSlideWrap .cartProductsWrap .cartPrdLeft .cartActionsWrap .prodQtyWrap button:last-child {
            margin: 0 !important;
        }

        .cartSlideWrapper .cartSlide .cartSlideTotal {
            direction: rtl;
        }
    }

    .paymentBottomFixed {
        .optionBtn {
            margin: 15px 0px 10px -10px !important;
        }
    }
    .shipmentMethod .shipmentMethodBody .card-body .shipmentPrice
    {
        text-align: right;
    }
    .card-payment-protection
    {
        .payment-protection
        {
            direction: rtl;
            .iconImage 
            {
                margin-left: 10px !important; 
                margin-right: 0;
            }
            .toolTipButton 
            {
                margin: 0 10px 0 0px!important;
            }
        }
    }
    .newToolTip .toolTipContent
    {
        text-align: right;
    }
    .FullScreenMinimize{
        left: 3px;
        right: auto;
    }
    .creditCardCollectForm
        #card-name {
            direction: rtl;
            .nameField {
                right: 0px !important;
                left: 24.49px !important;
            }
        }
        .twoField {
            .col-6 {
                // flex: 0 0 46.8%;
                // max-width: 46.8%;
                padding: 0px;
                &.expiry {
                    padding-left: 0rem;
                    padding-right: 0.70rem;
                }
                &.cvv {
                    padding-right: 0rem;
                    padding-left: 0.70rem;
                }
            }
      }
    .formFields {
        direction: rtl;
        .nameField {
            right: 0px !important;
            left: 24.49px !important;
        }
    }
    
    .ccGatewayCheckboxCard {
        .saveDetailsFooter {
            direction: rtl;
            text-align: right;
            .footContent {
                margin-right: 12px;
            }
        }
    }
    .ccVerificationFooter {
        .niftText{
            font-size: 13px;
            word-spacing: 5px;
            line-height: 16px;
        }
    }
    .niftVerifiedText .niftText{
        font-size: 13px;
        word-spacing: 5px;
        line-height: 16px;
    }
    
}

@media only screen and (max-width: 480px) {
    body[dir="rtl"] {

        .receiptFirstBox .bigReceiptHeadingBox .left,
        .receiptFirstBox .bigReceiptHeadingBox .right {
            font-size: 19px !important;
            word-spacing: 4px;
        }
    }
}