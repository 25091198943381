#iframe3DSContent {
    margin-top: 11vh !important;
    max-width: 700px !important;
    height: 460px !important;
    width: auto !important;
    div{
      max-width: 800px !important;
      height: 480px !important;
    }
}
#ContainerMain {
    padding: 10px 0px 10px 0px !important;
    margin-left: 0px !important;
}
.niftBackBtn{
  margin-top: 7px;
}

.ccVerificationFooter {
  margin-top: 20px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  fieldset{
    min-height: 35px;
    border: 1.5px solid #e0e0e0;
    margin: 0px auto;
    padding: 0px 4px;
    border-radius: 7px;
    // height: 50px;
    // width: 100px;
    min-width: 77px;
    max-width: 77px;
    min-height: 40ticketpx;
    margin-bottom: unset;
    font-size: 8px;
    legend {
      font-weight: bold;
      font-size: 7px;
      padding: 0 3px;
      margin-bottom: 0px;
      color: #91989C;
    }
    div {
      margin: 0px;
      text-align: center;
    }
  }
  .divider{
    height: 35px;
    margin: 0px 10px;
    width: 2px;
  }
  .niftText{
    font-size: 0.68rem;
    text-align: initial;
    .red{
      font-size: 0.85rem;
      color: $red;
    }
    .blue{
      font-size: 0.85rem;
      color: $navyblue;
    }
  }
}

.niftVerifiedText{
  margin-top: 20px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  .divider{
    height: 35px;
    margin: 0px 10px;
    width: 2px;
  }
  .niftText{
    font-size: 0.68rem;
    text-align: initial;
    .red{
      font-size: 0.85rem;
      color: $red;
    }
    .blue{
      font-size: 0.85rem;
      color: $navyblue;
    }
  }
}

.niftInfoIcon{
  margin-top: 5px;
  margin-bottom: 80px;
  display:inline-block;
  color: #91989c;
  font-size: 0.68rem;
  .MuiIconButton-colorPrimary {
    color: RGB(var(--theme-custom-color));
    background-color: RGB(var(--lighten-theme-custom-color));
    // color: $white;
  }
}


.PGWHPCCARDContainer, .PGWHPCACCTContainer {
  .pgwhpccardelemscontainer {
    // font-family: "SF Pro Text" !important;

    .btn:focus {
      box-shadow: none;
    }
  }

  .btn_Process_Card, .btn_Process_Acct {
    background: RGB(var(--theme-custom-color));
    color: $white;
    border: solid 2px RGB(var(--theme-custom-color));
    font-size: 18px;
    padding: 11.5px;
    font-family: $SF_TextRegular;
    font-weight: normal;
    border-radius: 4.52082px;
    margin-top: 25px;
    margin-bottom: 10px;
    width: 100%;
    text-align: center;

    &:hover {
      background: var(--darker-lighten-theme-custom-color);
      border: solid 2px var(--darker-lighten-theme-custom-color);
      color: $white;
    }

    &:active {
      background: var(--darker-lighten-theme-custom-color);
      color: $white;
      border: solid 2px var(--darker-lighten-theme-custom-color);
    }

    &:focus,
    &.focus {
      color: #ffffff;
      background: var(--darker-lighten-theme-custom-color);
      border-color: var(--darker-lighten-theme-custom-color);
    }

    &.disabled {
      cursor: not-allowed;
      background: $grayBg;
      color: $grayCol;
      border-color: $grayBg;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      color: #ffffff;
      background: var(--lighten-theme-custom-color);
      border-color: var(--lighten-theme-custom-color);
    }
  }

  .btn:focus {
    box-shadow: none;
  }
}

.pgwhpcfield_OTP_Container{
	text-align: center;
	margin-bottom: -10px;
	#btn_Resend_OTP {
		margin: 0 !important;
		width: 60% !important;
		margin-top: 10px !important;
		background-color: $white;
		color: RGB(var(--theme-custom-color));
		border: 0;
		font-weight: 600;
	}
}

.niftFormCSS .pgwhpcfield_NameOnCard_Container input,
.niftFormCSS .pgwhpcfield_CardNumber_Container input,
.niftFormCSS .pgwhpcfield_CardCVV_Container input,
.niftFormCSS .pgwhpcacctfield input[type=text],
.niftFormCSS .pgwhpcacctfield select
{
  line-height: 1.1876em !important;
  letter-spacing: 0.00938em !important;
  font-size: 1rem !important;
  transition: border-color 0.15s ease-in-out 0s !important;
  box-sizing: border-box !important;
  padding: 10px !important;
  background: transparent !important;
  color: rgba(0, 0, 0, 0.54) !important;
  outline: none !important;
  flex: 1 1 !important;
  cursor: text !important;
  border-radius: 4px !important;
  border: 1px solid #91989c !important;
  width: 100% !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-display: swap !important;
  font-weight: normal !important;
  font-size: 1rem !important;
  font: 400 16px / 21.0016px "Arial" !important;
  text-align: left !important;
}

.niftFormCSS label {
  flex: 0 0 100%;
  color: #192631c7;
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  font-family: "SF Pro Display" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-display: swap !important;
  font-weight: normal !important;
  font-size: 1.05rem !important;
  margin-bottom: 14px;
}

.niftFormCSS iframe .gw-proxy-nameOnCard,
.niftFormCSS iframe .gw-proxy-number,
.niftFormCSS iframe .gw-proxy-securityCode {
  height: 50px !important;
  width: 100% !important;
}

.pgwhpcfield_CardExpiry_Container {
  color: #9798aa !important;
  display: flex !important;
  flex-wrap: wrap !important;
  font-size: 1.65rem !important;
}

.pgwhpcfield_CardExpiry_Container label {
  font-size: 1rem !important;
}

.pgwhpcfield_CardExpiry_Container iframe {
  width: 45.13% !important;
  border: 1px solid #91989c !important;
}

.niftFormCSS iframe .gw-proxy-expiryMonth,
.niftFormCSS iframe .gw-proxy-expiryYear {
  height: 50px !important;
  text-align: left !important;
}

.niftFormCSS #pgwhpcfld_CardExpiryMonth {
  margin: 0px 10px 0px 0px !important;
  width: 145px !important;
  text-align: left !important;
}

.niftFormCSS #pgwhpcfld_CardExpiryYear {
  margin: 0px 0px 0px 10px !important;
  width: 145px !important;
  text-align: left !important;
}

.niftFormCSS #pgwhpcfld_CardExpiryMonth,
.niftFormCSS #pgwhpcfld_CardExpiryYear {
  height: 40px !important;
  line-height: 1.1876em !important;
  letter-spacing: 0.00938em !important;
  transition: border-color 0.15s ease-in-out 0s !important;
  box-sizing: border-box !important;
  padding: 10px !important;
  background: transparent !important;
  color: rgba(0, 0, 0, 0.54) !important;
  outline: none !important;
  flex: 1 1 !important;
  cursor: text !important;
  border-radius: 4px !important;
  border: none !important;
  width: 100% !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-display: swap !important;
  font-weight: normal !important;
  font-size: 1rem !important;
  font: 400 16px / 21.0016px "Arial" !important;
}

#pgwhpcfld_CardExpiryMonth {
  width: 145px !important;
}

#pgwhpcfld_CardExpiryYear {
  width: 145px !important;
}
@media (max-width:700px) {
  #iframe3DSContent{
    margin-top: 17vh !important;
    max-width: 500px !important;
    margin-left: 3% !important;
    margin-right: 3% !important;
  }
}

@media (min-width:481px) and (max-width:700px) {

  .pgwhpcfield_CardExpiry_Container iframe {
    width: 45.13% !important;
    border: 1px solid #91989c !important;
  }
}

@media (min-width:350px) and (max-width:480px) {

  .pgwhpcfield_CardExpiry_Container iframe {
    width: 45.15% !important;
    border: 1px solid #91989c !important;
  }
}

@media (min-width:180px) and (max-width:349px) {
  .pgwhpcfield_CardExpiry_Container iframe {
    width: 45.0375% !important;
    border: 1px solid #91989c !important;
  }
}