@media (min-width:180px) and (max-width:480px) {
    body {
        overflow-x: hidden !important;
    }

    .accountDrop {
        .dropdown-menu {
            margin: 0 !important;
            transform: translate3d(0px, 0px, 0px) !important;
            top: 57px !important;
            right: 0px !important;
            left: unset !important;
        }
    }

    .dialogPopup {
        width: 250px;
        &.dialogExpired {
            width: 255px !important;
            .expired {
                margin: 19px 0px;
                padding: 5px;
                .iconImage {
                    font-size: 5rem;
                    margin-top: 1rem;
                    margin-bottom: 1rem;
                }
                .returnBtn {
                     button {
                        width: 90%;
                        font-size: 12px;
                     }
                }
            }
            .message{
                h3 {
                    font-size: 0.8rem !important;
                    margin-top: 5px;
                }
                p {
                    font-size: 12px;
                }
            }
        }
    }

    .easyPaisaLogo {
        width: 260px;
    }

    #ribbionParent {
        .mapRibbion {
            p {
                font-size: 15px !important;
            }
        }
    }

    .customGmapWrapper {
        max-width: 411px;
        margin: 24px 24px 0 24px !important;
    }

    .FullScreenGmapWrapper {
        max-width: 1411px;
        .gm-bundled-control {
            margin: 4px !important;
            // bottom: 20vh!important;
            bottom: calc(24vh) !important;
        }
        .GmapContainerElement {
            height: calc(82.65vh) !important;
        }
    }
    .FullScreenAddressWrapper
    {
        width: 95%;
        right:0 !important;
        .address-pin-container
        {
            margin: 14px 0px 0 0;
            // margin: 10px auto 0 auto;
            width: 100%;
        }
    }

  .footerWrap
  {
    padding: 0 0 15px 0;
    position: relative;
    bottom: 0;
    .container {
      .footer-main {
        ul {
          margin:0 0 0 10px;
          padding:0 0 0 10px;
          li {
            span {
                margin-right:15px;
            }
          }
        }
      }
    }
  }
}


@media (max-width:480px)
{
    .dialogPopup {
        width: 370px;
        &.dialogExpired{
            width: 355px;
            .expired{
                padding: 5px
            }
            .message{
                h3{
                    font-size: 1rem;
                }
            }
        }
    }
    .cartSlideWrapper {
        .cartSlide {
            .cartSlideTotal{
                padding:23px 15px;
            }
            .insideCartSlideWrap {
                .cartProductsWrap {
                    .cartPrdLeft {
                        .cartActionsWrap {
                            display: flex;
                            width: 90%;
                        }
                    }
                }
            }
        }
    }

    .SlideOpenFsContainer .FullScreenGmapWrapper {
        margin:-30px 0 0 0;
    }
    .FullScreenLocationBtnWrapper {
        position: unset;
        left: 0;
        bottom: 0;
        width: 100%;
        height: calc(100vh - 200px);
        display: flex;
        align-items: flex-end;
        justify-content: center;
        .confirm-location {
            button {
                padding: 5px 80px !important;
            }
        }
    }
    .shipmentMethod .shipmentMethodBody .description .shipmentDesc
    {
        min-width: 140px;
        max-width: 189px;
    }
}

@media only screen and (max-width: 500px) {
    .dialogPopup {
        width: 370px;
        &.dialogExpired{
            width: 425px;
            .message{
                h3{
                    font-size: 1.25rem;
                }
            }
        }
        &.langDialog {
            width: 95% !important;
            .dropdownAccordion {
                .customRadio {
                    .card {
                        .card-header {
                            padding: 7px 5px;
                            .LangName {
                                font-size: 14px;
                            }
                            .LangCountry {
                                font-size: 11px;
                            }
                        }
                    }
                }
                .card-body {
                    font-size: 10px;
                }
            }
        }
        .dialogPopupInv{
            .MuiTypography-h6 {
                font-size: 22px !important;
            }

            .MuiDialogContentText-root {
                font-size: 12px !important;
            }
        }
    }

    .headerDropdown {
        .name-full {
            display: none;
        }
        .name-alias {
            display: inline-block !important;
        }
    }


    .FullScreenGmapWrapper {
        .gm-bundled-control {
            bottom: calc(28vh) !important;
        }
    }

    .fs-container {
        .FullScreenGmapWrapper {
            .GmapContainerElement {
                height: calc(77.65vh) !important;
            }
        }
    }

    .receiptSection{
        .receiptDiscounts{
            .receiptSub{
                .left {
                    width: 65%;
                }
            }
        }
    }
    
    .easyPaisaLogo {
        width: 260px;
    }
}

@media only screen and (min-width: 500px) {
    .FullScreenGmapWrapper {
        .gm-bundled-control {
            bottom: calc(25vh) !important;
        }
    }
}

@media (min-width:180px) and (max-width:320px) {
    .receiptSection {
        .bottomPattern {
            bottom: -16px;
        }
    }
}

@media (min-width:321px) and (max-width:360px) {
    .receiptSection {
        .bottomPattern {
            bottom: -17px;
        }
    }
}