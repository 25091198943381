:root {
  --animate-duration: 800ms;
  --animate-delay: 0.9s;
}
html,
body {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

body {
  font-family: $SF_TextRegular !important;
  color: $black-blue;
  background: #fff;
  overflow-y: auto !important;
}


button:focus {
  outline: none !important;
  outline: 0px auto -webkit-focus-ring-color !important;
}

.alert {
  overflow-wrap: break-word;
}

a {
  outline: 0 none;
}

h1 {
  font-family: $SF_TextRegular;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.bg-white {
  background: #fff !important;
}

.oneTap {
  color: RGB(var(--theme-custom-color)) !important;
  font-size: 32px !important;
  margin-left: 0 !important;
}

a:not([href]):not([tabindex]) {
  cursor: pointer;
}


.form-control {
  color: $black-blue;

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $grayCol;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $grayCol;
  }

  &:-ms-input-placeholder {
    /* IE 10+ */
    color: $grayCol;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: $grayCol;
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }
}

.react-code-input {
  width: 100%;
  input {
    font-family: monospace;
    border-radius: 6px;
    border: 0px solid lightgrey !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 0px 0px !important;
    margin: 4px;
    padding-left: 10px;
    width: 36px;
    height: 42px;
    font-size: 32px;
    box-sizing: border-box;
    color: black;
    /* background-color: white;*/
  }
}

.btn {
  font-size: 18px;
  &:focus {
    box-shadow: none;
  }
}

.btn-error{
  &.shipmentNotAvailable{
    font-style: normal;
    font-size: 18px;
    opacity: 1 !important;
  }

  background-color: $pink !important;
  padding: 5px !important;
  border: none !important;
  :disabled {
    background:  $red !important;
    color: aliceblue !important;
    border: solid 2px #fff !important;
  }
}

.btn-primary {
  background: RGB(var(--theme-custom-color));
  color: $white;
  border: solid 2px RGB(var(--theme-custom-color));
  font-size: 18px;
  padding: 11.5px;
  font-family: $SF_TextRegular;
  font-weight: normal;
  border-radius: 4.52082px;

  &:hover {
    background: var(--darker-lighten-theme-custom-color);
    border: solid 2px var(--darker-lighten-theme-custom-color);
    color: $white;
  }

  &:active {
    background: var(--darker-lighten-theme-custom-color);
    color: $white;
  }

  &:disabled:not(.btn-error) {
    color: #ffffff;
    background: var(--darker-lighten-theme-custom-color);
    border-color: var(--darker-lighten-theme-custom-color);
  }

  &:focus,
  &.focus {
    color: #ffffff;
    background: var(--darker-lighten-theme-custom-color);
    border-color: var(--darker-lighten-theme-custom-color);
  }

  &.disabled {
    cursor: not-allowed;
    background: $grayBg;
    color: $grayCol;
    border-color: $grayBg;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: #ffffff;
    background: var(--darker-lighten-theme-custom-color);
    border-color: var(--darker-lighten-theme-custom-color);
  }
}

.btn-outline-primary {
  border: solid 2px RGB(var(--theme-custom-color));
  background: $white;
  font-size: 18px;
  padding: 11.5px;
  font-family: $SF_TextRegular;
  font-weight: normal;
  border-radius: 4.52082px;
  color: RGB(var(--theme-custom-color));

  &:hover {
    background: RGB(var(--theme-custom-color));
    border: solid 2px RGB(var(--theme-custom-color));
  }

  &.disabled {
    cursor: not-allowed;
    background: none;
    color: $white;
    border-color: $grayCol;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active &:active {
    color: #ffffff;
    background: var(--darker-lighten-theme-custom-color);
    border-color: var(--darker-lighten-theme-custom-color);
  }
}

.btn-outline-white {
  border: solid 2px $white;
  background: RGB(var(--theme-custom-color));
  font-size: 18px;
  padding: 11.5px;
  font-family: $SF_TextRegular;
  font-weight: normal;
  border-radius: 4.52082px;

  &:hover {
    background: $white;
    border: solid 2px $white;
  }

  &.disabled {
    cursor: not-allowed;
    background: none;
    color: $white;
    border-color: $grayCol;
  }
}

.btn-blank {
  background: none;
}

.nHref {
  background: none;
  border: none;
  outline: 0 none;
  padding: 0;
  margin: 0;
  cursor: pointer;

  &:focus {
    outline: 0 none;
  }
}

.GmapWrapper {
  max-width: 411px;
  margin: 24px auto 0 !important;

  .gm-bundled-control {
    display: none;
  }
}

.GmapLoadingElement {
  height: 100% !important;
}

.GmapContainerElement {
  height: 280px !important;
}

.GmapMapElement {
  height: 100% !important;
  @include border-radius(9.47205px);
}

#mapTopLayer {
  width: 100%;

  .fullscreen-control {
    z-index: 10001;
    background: none rgb(255, 255, 255);
    border: 0px;
    margin: 0px 10px;
    padding: 0px;
    text-transform: none;
    appearance: none;
    position: absolute;
    cursor: pointer;
    user-select: none;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    overflow: hidden;
    right: -4px;
    top: 10px;
    width: 30px !important;
    height: 30px !important;
    border-radius: 10px;

    &.fullscreen-location-pin-control {
      top: 0px !important;
    }

    img {
      height: 30px;
      width: 20px;
    }
  }
}

.mapRibbion {
  z-index: 10000001 !important;
  position: absolute;
  width: 100%;
  height: 45px;
  right: 0px;
  top: 0px;
  background: #d2aaff29;
  // background: #9c98ab4d;
  -webkit-backdrop-filter: blur(157.781px);
  backdrop-filter: blur(157.781px);

  p {
    z-index: 10000001 !important;
    font-family: $SF_TextRegular;
    font-style: normal;
    line-height: 13px;
    text-align: left;
    color: $black-blue;
    margin: 15px 7px;
    font-weight: 400;
    font-size: 13.757px;
    line-height: 13px;
    font-weight: 600;
  }

  button {
    position: relative;
    top: 4px;
    z-index: 10000001 !important;
    background: none white;
    border: 0px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    width: 30px !important;
    height: 30px !important;
    border-radius: 15px;
    overflow: hidden;
    margin: 0px 10px !important;
    box-shadow: 0px 0.91872px 0.91872px rgba(0, 0, 0, 0.25);

    img {
      z-index: 10000001 !important;
      width: 100%;
    }
  }
}

.btn-tiny {
  font-size: 10px !important;
  padding: 3px 9px !important;
}

.width150 {
  width: 150px;
  @media only screen and (max-width: 410px) {
      width: 8.25rem;
  }

  @media only screen and (max-width: 380px) {
      width: 9.25rem;
  }
}


.paymentBottomFixed {
  height: auto;
  background: RGB(var(--theme-custom-color));
  color: $white;

  .paymentBottomOptionBtn {
    margin: 15px auto;
    @media only screen and (max-width: 480px) {
      margin: 10px auto;
    }
    &.col-12 {
      margin: 10px 0px 10px 0px;

      .btn:first-child {
        margin: 0 10px;
      }

      @media only screen and (max-width: 480px) {
        .btn:first-child {
          margin: 0 5px;
        }
      }
    }

    .btn:first-child {
      margin: 0 2px;
    }

    .btn-twice:last-child {
      margin: 0 0px 0 20px;
    }
    .optionBtn {
      margin-top: 17px;
    }
  }

  .btn-primary {
    background: white;
    color: RGB(var(--theme-custom-color)) !important;
    border: solid 2px RGB(var(--theme-custom-color)) !important;
  }

  .btn-block {
    display: block !important;
    width: 100% !important;
  }

  .btn-primary.disabled,
  .btn-primary:disabled {
    background: RGB(var(--theme-custom-color));
    color: aliceblue !important;
    border: solid 2px #fff !important;
  }

  .btn-outline-primary {
    color: white;
    background: RGB(var(--theme-custom-color)) !important;
    border: solid 2px #fff !important;

    span {
      font-size: 20px;
    }

    &:hover {
      background: white;
    }

    &:focus {
      background: white;
    }
  }

  .notClickedBtn {
    border-color: $white;
    color: RGB(var(--theme-custom-color)) !important;
    background: #fff;

    &:active {
      border-color: $white !important;
      color: RGB(var(--theme-custom-color)) !important;
      background: #fff !important;
    }
  }
}

.font18 {
  font-size: 18px;
}

.bSecureIcon {
  background: #000;
  width: 100%;
  font-size: 24px;
  font-family: $SF_ProDisplay;
  border: none;
  color: #fff !important;
  padding: 0;
  line-height: 50px;
  text-align: center;

  img {
    margin-left: 7px;
    vertical-align: sub;
  }

  &.powered {
    background: $black-blue;
    font-size: 19px;
    img {
      margin-left: 5px;
      vertical-align: text-bottom;
      height: 24px;
    }
  }
}

.otpScreen {
  position: fixed;
  overflow: hidden;
  overscroll-behavior: contain !important;
}

.topStatus {
  height: 80px;
  background: var(--lighten-theme-custom-color);
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      height: 34px;
      width: 34px;
      box-shadow: 0px 4px 4px rgba(19, 123, 222, 0.15);
      background: RGB(var(--theme-custom-color));
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      .iconW {
        color: $white !important;
      }

      .iconT {
        color: RGB(var(--theme-custom-color)) !important;
      }
    }

    .statusText {
      font-family: $SF_ProDisplay;
      font-size: 24px;
      color: $black-blue;
      margin-left: 12px;

      &.oneTap {
        color: RGB(var(--theme-custom-color));
        font-size: 32px;
      }
    }
  }

  &.orderStatus {
    &.Success {
      background: $green;
      i{
        font-size: 2.55rem;
      }
    }

    &.Processing {
      background: $yellow;
    }

    &.Failure {
      background: $pink;
    }

    .transBgicon {
      height: 34px;
      width: 34px;
      display: flex;
      justify-content: center;
      align-items: center;

      &.f22 {
        font-size: 22px;
      }

      .iconW {
        color: $white;
      }
    }
  }

  .retryOtp {
    cursor: pointer;
  }
}

.stepStatus {
  background: var(--lighten-theme-custom-color);

  .stepIcons {
    margin: 0 7px;

    .icon {
      width: 28px;
      height: 28px;
      font-size: 13px;
    }

    .statusText {
      font-size: 16px;
    }
  }
  .stepStatusOptions {
    // height: 60px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    @media only screen and (max-width: 600px) {
      height: 55px;
    }
  
    .stepIcons {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 10px;
      cursor: default;
  
      &.clickable {
        cursor: pointer;
      }
  
      .icon {
        height: 34px;
        width: 34px;
        box-shadow: 0px 4px 4px rgba(19, 123, 222, 0.15);
        background: var(--lighten-theme-custom-color);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        font-size: 15px;
  
        &.iconPrev {
          box-shadow: none !important;
          background: none !important;
        }
        .iconT {
          color: RGB(var(--theme-custom-color)) !important;
          font-size: 1.25rem;
        }
      }
  
      .stepNo {
        color: var(--darker-lighten-theme-custom-color);
        font-size: 20px;
        font-family: $SF_TextRegular !important;
      }
  
      .statusText {
        display: none;
        font-size: 24px;
        font-family: $SF_ProDisplay;
        margin-left: 10px;
      }
  
      &.active {
        .icon {
          background: var(--darker-lighten-theme-custom-color);
  
          .iconW {
            color: $white !important;
          }
        }
      }

      &.activeText {
        .statusText {
          display: flex;
        }
      }
    }
  }

  .stepStatusReview {
    display: flex;
    margin: 0 auto;
    padding: 0 11.5px 11.5px 11.5px;
    // background: var(--lighten-theme-custom-color);/

    @media only screen and (max-width: 600px) {
      padding: 0px;
      width: 75%;
      margin: 0px auto 10px auto;
    }

    &.cartStatusReview{
        button {
        i {
          transform: rotate(180deg);
          -moz-transform: rotate(180deg);
          -webkit-transform:  rotate(180deg);
          -ms-transform:  rotate(180deg);
          -o-transform: rotate(180deg);
        }
      }
    }
    button {
      max-width: 310px;
      // width: 35%;
      padding: 0px;
      margin: 5px auto;

      @media only screen and (max-width: 600px) {
        width: 100%;
      }
      &:hover {
        background: RGB(var(--lighten-theme-custom-color));
        color: RGB(var(--theme-custom-color));
      }
      &:disabled{
        background-color: $grayBg;
        border-color: $mid-grey;
        color: $dark-grey;
      }

      span{
        font-style: normal;
        font-weight: 600;
        // text-transform: uppercase;
        letter-spacing: 0.3px;
        font-size: 15px;
      }
      i {
        margin-left: 0.75rem;
      }
    }
  }
  
}

.progressWrap {
  height: 2px;
  background: $grayBg;
  position: relative;
  overflow: hidden;

  .progressLine {
    position: absolute;
    height: 2px;
    top: 0;
    left: 0;
    background: RGB(var(--theme-custom-color));
  }
}

.formInput {
  position: relative;

  textarea {
    resize: none;
    height: auto !important;
  }

  input[required="required"] {
    box-shadow: none;
  }

  input,
  textarea {
    background: none;
    font-size: 20px;
    display: block;
    padding: 12px;
    height: 50px;
    border: solid 1px $grayCol;

    &:focus {
      outline: none;
      box-shadow: none;
      border-color: RGB(var(--theme-custom-color));
    }
  }

  .form-control:disabled,
  .form-control[readonly] {
    background-color: $grayBg;
  }

  &.md-form {
    position: relative;

    label {
      color: $grayCol;
      position: absolute;
      top: 0.8rem;
      left: 12px;
      font-size: 20px;
      line-height: 23px;
      cursor: text;
      -webkit-transition: 0.2s ease-out;
      -moz-transition: 0.2s ease-out;
      -o-transition: 0.2s ease-out;
      -ms-transition: 0.2s ease-out;
      transition: 0.2s ease-out;

      &.active {
        font-size: 13px;
        background: $white;
        padding: 0 6px;
        color: RGB(var(--theme-custom-color));
        -webkit-transform: translateY(-115%);
        -moz-transform: translateY(-115%);
        -ms-transform: translateY(-115%);
        -o-transform: translateY(-115%);
        transform: translateY(-115%);
      }
    }

    .prefix {
      position: absolute;
      width: 3rem;
      font-size: 2rem;
      -webkit-transition: color 0.2s;
      -moz-transition: color 0.2s;
      -o-transition: color 0.2s;
      -ms-transition: color 0.2s;
      transition: color 0.2s;

      &.active {
        color: RGB(var(--theme-custom-color));
      }
    }

    .prefix ~ input,
    .prefix ~ textarea {
      margin-left: 3rem;
      width: 92%;
      width: calc(100% - 3rem);
    }

    .prefix ~ textarea {
      padding-top: 0.8rem;
    }

    .prefix ~ label {
      margin-left: 3rem;
    }

    @media only screen and (max-width: 992px) {
      .prefix ~ input {
        width: 86%;
        width: calc(100% - 3rem);
      }
    }

    @media only screen and (max-width: 600px) {
      .prefix ~ input {
        width: 80%;
        width: calc(100% - 3rem);
      }
    }

    // .input-group{
    //     border:1px solid #eee
    // }
    // .input-group .form-control{
    //     margin:0
    // }
    // .input-group ::-webkit-input-placeholder{
    //     padding-left:7px;
    //     padding-top:2px
    // }
    // .input-group :-moz-placeholder{
    //     padding-left:7px;
    //     padding-top:2px
    // }
    // .input-group ::-moz-placeholder{
    //     padding-left:7px;
    //     padding-top:2px
    // }
    // .input-group :-ms-input-placeholder{
    //     padding-left:7px;
    //     padding-top:2px
    // }
    .form-inline fieldset {
      margin-right: 1.5rem;
    }
  }

  &.pressed {
    input,
    textarea {
      border-color: RGB(var(--theme-custom-color));

      &:focus {
        border-color: RGB(var(--theme-custom-color));
        box-shadow: none;
      }

      &:focus ~ label,
      &:valid ~ label {
        color: RGB(var(--theme-custom-color));
      }

      // &:focus ~ .bar:before {
      // }
    }

    label {
      color: RGB(var(--theme-custom-color));
    }

    .bar {
      &:before {
        content: "";
        background: RGB(var(--theme-custom-color));
      }
    }
  }

  &.success {
    input,
    textarea {
      border-color: $green;

      &:focus {
        border-color: $green;
        box-shadow: none;
      }

      &:focus ~ label,
      &:valid ~ label {
        color: $green;
      }

      // &:focus ~ .bar:before {
      // }
    }

    label {
      color: $green;
    }

    .bar {
      &:before {
        content: "";
        background: $green;
      }
    }

    .successMessage {
      font-size: 13px;
      color: $green;
    }
  }

  &.error {
    input,
    textarea {
      border-color: $pink;
      font-size: 0.9375rem;

      &:focus {
        border-color: $pink;
        box-shadow: none;
      }

      &:focus ~ label,
      &:valid ~ label {
        color: $pink;
      }

      // &:focus ~ .bar:before {
      // }
    }

    label {
      color: $pink;
      font-size: 0.9375rem;
    }

    .bar {
      &:before {
        content: "";
        background: $pink;
      }
    }

    .errorMessage {
      font-size: 13px;
      color: $pink;
    }
  }

  &.disabled {
    input,
    textarea {
      background: $grayBg;
      border: none;
      cursor: not-allowed;
    }

    label {
    }
  }

  &.checkedtxt {
    .form-control {
      border-color: RGB(var(--theme-custom-color));
    }

    .iconBg {
      background: RGB(var(--theme-custom-color));
      border-radius: 50%;
      height: 24px;
      width: 24px;
      display: inline-block;
      color: #fff;
      font-size: 12px;
      text-align: center;
      line-height: 27px;
      position: absolute;
      right: -12px;
      top: -12px;

      .icomoon-check {
        &:before {
          color: #fff;
        }
      }
    }
  }
}


.customRadio {
  .accordion {
    position: relative;
    padding: 0px;

    .card {
      border: solid 1px $grayCol;
      border-radius: 4px;
      box-shadow: none;
      width: 100%;
      margin-bottom: 20px;
      &.shipmentDisabled {
        background: #afa1a12b;
        border: solid 1px $pink;
          .shipmentMethodHeader {
            .shipmentHeaderBody {
              .information {
                border-left: solid 1px $pink;
              }
            }
          }
      }
      .card-header {
        // height: 44px;
        line-height: 22px;
        padding: 12px 30px;
        font-size: 20px;
        min-height: auto;
        border-radius: 4px;
        justify-content: center;
        border: none;
        position: relative;
        cursor: pointer;
        color: $black-blue;

        .overflowText {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          margin-bottom: 5px;
        }

        p {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          margin-bottom: 0;
        }

        span {
          font-style: normal;
          font-weight: 600;
          font-size: 10px;
          text-transform: uppercase;
          letter-spacing: 0.3px;
          color: $mid-grey;
        }

        .paymentType {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .information {
            width: 190px;
            text-align: left;
            margin-left: 10px;

            .overflowText {
              margin-bottom: 0;
            }
          }
        }

        &.card-header-left {
          display: block;
          text-align: left;
        }

        &.ep-card-header-left {
          display: block;
          text-align: left;
          height: 114px !important;
          border-radius: 4%;
          border: 1px solid #b9c0c4;
          box-sizing: border-box;
          border-radius: 11px;
        }
      }

      .editAddHeader {
        display: none;

        .editAdd {
          font-size: 14px;
          border-radius: 0px;
          font-family: $SF_TextRegular;
          font-weight: 400;
        }
      }

      .saveAddHeader {
        display: none;

        .saveAdd {
          font-size: 14px;
          border-radius: 0px;
          font-family: $SF_TextRegular;
          font-weight: 400;
        }
      }

      .card-body {
        font-size: 14px;
        line-height: 16px;
        padding: 20px 15px;
        color: $mid-grey;
        border-top: solid 1px $grayCol;

        .map {
          margin-bottom: 20px;
        }

        .customRadio {
          flex: 100%;
        }
      }

      &.addNew {
        border: dashed 1px $grayCol;

        .editAddHeader {
          display: none;
        }

        &.activeShow {
          border: solid 1px RGB(var(--theme-custom-color));

          .card-header {
            background: var(--lighten-theme-custom-color);

            &:after {
              content: "";
              height: 24px;
              width: 24px;
              display: block;
              background: RGB(var(--theme-custom-color));
              border-radius: 50%;
              position: absolute;
              right: -12px;
              top: -12px;
            }

            &:before {
              content: "";
              position: absolute;
              height: 7px;
              width: 15px;
              top: 2px;
              right: -7px;
              margin: -7px 0 0 -6px;
              border: 1px solid #fff;
              border-width: 0 0 1px 1px;
              background: none;
              border-radius: 0 !important;
              transform: rotate(-45deg);
              -moz-transform: rotate(-45deg);
              -webkit-transform: rotate(-45deg);
              -ms-transform: rotate(-45deg);
              -o-transform: rotate(-45deg);
              z-index: 1;
            }
          }

          .customRadio {
            .accordion {
              .card {
                .card-header {
                  background: transparent;

                  &:after {
                    content: "";
                    height: 24px;
                    width: 24px;
                    display: block;
                    background: transparent;
                    border-radius: 50%;
                    position: absolute;
                    right: 0;
                    top: 0;
                  }

                  &:before {
                    content: "";
                    position: absolute;
                    height: 0px;
                    width: 0px;
                    top: 0px;
                    right: 0px;
                    margin: 0;
                    border: 0px solid #fff;
                    border-width: 0 0 1px 1px;
                    background: none;
                    border-radius: 0 !important;
                    transform: rotate(-45deg);
                    -moz-transform: rotate(-45deg);
                    -webkit-transform: rotate(-45deg);
                    -ms-transform: rotate(-45deg);
                    -o-transform: rotate(-45deg);
                    z-index: 1;
                  }
                }

                &.activeShow {
                  border: solid 1px RGB(var(--theme-custom-color));

                  .card-header {
                    background: var(--lighten-theme-custom-color);

                    &:after {
                      content: "";
                      height: 24px;
                      width: 24px;
                      display: block;
                      background: RGB(var(--theme-custom-color));
                      border-radius: 50%;
                      position: absolute;
                      right: -12px;
                      top: -12px;
                    }

                    &:before {
                      content: "";
                      position: absolute;
                      height: 7px;
                      width: 15px;
                      top: 2px;
                      right: -7px;
                      margin: -7px 0 0 -6px;
                      border: 1px solid #fff;
                      border-width: 0 0 1px 1px;
                      background: none;
                      border-radius: 0 !important;
                      transform: rotate(-45deg);
                      -moz-transform: rotate(-45deg);
                      -webkit-transform: rotate(-45deg);
                      -ms-transform: rotate(-45deg);
                      -o-transform: rotate(-45deg);
                      z-index: 1;
                    }
                  }
                }
              }
            }
          }
        }

        &.error {
          border: solid 1px #de132a;

          .card-header {
            background: var(--lighten-theme-custom-color);

            &:after {
              content: "";
              height: 24px;
              width: 24px;
              display: block;
              background: #de132a;
              border-radius: 50%;
              position: absolute;
              right: -12px;
              top: -12px;
            }

            &:before {
              content: "";
              position: absolute;
              height: 7px;
              width: 15px;
              top: 2px;
              right: -7px;
              margin: -7px 0 0 -6px;
              border: 1px solid #fff;
              border-width: 0 0 1px 1px;
              background: none;
              border-radius: 0 !important;
              transform: rotate(-45deg);
              -moz-transform: rotate(-45deg);
              -webkit-transform: rotate(-45deg);
              -ms-transform: rotate(-45deg);
              -o-transform: rotate(-45deg);
              z-index: 1;
            }
          }
        }
      }

      .myForm {
        display: flex;
        flex-wrap: wrap;

        .accEditSave {
          margin-left: -15px;
          margin-right: -15px;
          width: calc(100% - -30px);
          margin-top: -20px;
          margin-bottom: 20px;
          font-size: 14px;
          border-radius: 0px;
          font-family: $SF_TextRegular;
          font-weight: 400;
        }

        .saveAddHeader {
          flex: 1;
          order: -1;
        }
      }

      // .collapsing {
      //     -webkit-transition: none;
      //     transition: none;
      //     display: none;
      //     overflow: auto;
      //     height: auto;
      // }
      &.activeShow {
        border: solid 1px RGB(var(--theme-custom-color));

        > .card-header {
          background: var(--lighten-theme-custom-color);

          &:after {
            content: "";
            height: 24px;
            width: 24px;
            display: block;
            background: RGB(var(--theme-custom-color));
            border-radius: 50%;
            position: absolute;
            right: -12px;
            top: -12px;
          }

          &:before {
            content: "";
            position: absolute;
            height: 7px;
            width: 15px;
            top: 2px;
            right: -7px;
            margin: -7px 0 0 -6px;
            border: 1px solid #fff;
            border-width: 0 0 1px 1px;
            background: none;
            border-radius: 0 !important;
            transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            z-index: 1;
          }
        }

        .editAddHeader {
          display: flex;
          flex: 100%;
        }

        .saveAddHeader {
          display: none;
          opacity: 1;
        }

        .card-body {
          border-top: solid 1px RGB(var(--theme-custom-color));
        }

        .myForm {
          display: flex;
          flex-wrap: wrap;

          .accEditSave {
            margin-left: -15px;
            margin-right: -15px;
            width: calc(100% - -30px);
            margin-top: -20px;
            margin-bottom: 20px;
            font-size: 14px;
            border-radius: 0px;
            font-family: $SF_TextRegular;
            font-weight: 400;
          }

          .saveAddHeader {
            flex: 1;
            order: -1;
          }
        }

        .activeCard {
          border: solid 1px RGB(var(--theme-custom-color));

          > .card-header {
            background: var(--lighten-theme-custom-color);
            color: $black-blue;

            &:after {
              content: "";
              height: 24px;
              width: 24px;
              display: block;
              background: RGB(var(--theme-custom-color));
              border-radius: 50%;
              position: absolute;
              right: -12px;
              top: -12px;
            }

            &:before {
              content: "";
              position: absolute;
              height: 7px;
              width: 15px;
              top: 2px;
              right: -7px;
              margin: -7px 0 0 -6px;
              border: 1px solid #fff;
              border-width: 0 0 1px 1px;
              background: none;
              border-radius: 0 !important;
              transform: rotate(-45deg);
              -moz-transform: rotate(-45deg);
              -webkit-transform: rotate(-45deg);
              -ms-transform: rotate(-45deg);
              -o-transform: rotate(-45deg);
              z-index: 1;
            }
          }
        }
      }

      &.activeButton {
        .card-text {
          .editAdd {
            display: none;
          }
          .saveAdd {
            display: none;
          }
        }
      }
    }
  }

  &.nooverleyText {
    .accordion {
      .card {
        .card-header {
          line-height: 24px;

          p {
            display: -webkit-box;
            -webkit-line-clamp: unset;
            -webkit-box-orient: unset;
            overflow: auto;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.processingRadio {
  .accordion {
    position: relative;
    padding: 0px;

    .card {
      // border: solid 1px $grayCol;
      // border-radius: 4px;
      // box-shadow: none;
      border: none;
      width: 100%;
      margin-bottom: 20px;

      .card-header {
        // height: 44px;
        line-height: 22px;
        padding: 12px 30px;
        font-size: 20px;
        min-height: auto;
        border-radius: 4px;
        justify-content: center;
        border: none;
        position: relative;
        cursor: pointer;
        color: $black-blue;

        .overflowText {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          margin-bottom: 5px;
        }

        p {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          margin-bottom: 0;
        }

        span {
          font-style: normal;
          font-weight: 600;
          font-size: 10px;
          text-transform: uppercase;
          letter-spacing: 0.3px;
          color: $mid-grey;
        }

        .paymentType {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .information {
            width: 190px;
            text-align: left;
            margin-left: 10px;

            .overflowText {
              margin-bottom: 0;
            }
          }
        }

        &.card-header-left {
          display: block;
          text-align: left;
        }

        &.ep-card-header-left {
          display: block;
          text-align: left;
          height: 114px !important;
          border-radius: 4%;
          border: 1px solid #b9c0c4;
          box-sizing: border-box;
          border-radius: 11px;

          .ep-card {
            .icon {
              width: 26px;
            }

            p {
              font-size: 14px;
            }

            span {
              font-weight: 600;
              font-size: 10px;
              text-transform: uppercase;
              letter-spacing: 0.3px;
              color: $mid-grey;
              line-height: 1px;
            }

            .ep-card-body {
              text-align: right;

              .ep-card-detail {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .image {
                  max-width: 2rem;
                  min-width: 2rem;
                }

                .information {
                  width: 190px;
                  text-align: left;
                  margin-left: 10px;

                  .overflowText {
                    margin-bottom: 0;
                  }
                }

                .codImage {
                  text-align: center;
                  margin: auto;

                  img {
                    max-width: 45px;
                    max-height: 43px;
                    object-fit: cover;
                  }
                }

                .information {
                  span {
                    font-weight: 700 !important;
                    font-size: 9px;
                  }
                }
              }
            }
          }
        }
      }

      .editAddHeader {
        display: none;

        .editAdd {
          font-size: 14px;
          border-radius: 0px;
          font-family: $SF_TextRegular;
          font-weight: 400;
        }
      }

      .saveAddHeader {
        display: none;

        .saveAdd {
          font-size: 14px;
          border-radius: 0px;
          font-family: $SF_TextRegular;
          font-weight: 400;
        }
      }

      .card-body {
        font-size: 14px;
        line-height: 16px;
        padding: 20px 15px;
        color: $mid-grey;
        border-top: solid 1px $grayCol;

        .map {
          margin-bottom: 20px;
        }

        .customRadio {
          flex: 100%;
        }
      }

      &.addNew {
        border: dashed 1px $grayCol;

        .editAddHeader {
          display: none;
        }

        &.activeShow {
          border: solid 1px RGB(var(--theme-custom-color));

          .card-header {
            background: var(--lighten-theme-custom-color);

            &:after {
              content: "";
              height: 24px;
              width: 24px;
              display: block;
              background: RGB(var(--theme-custom-color));
              border-radius: 50%;
              position: absolute;
              right: -12px;
              top: -12px;
            }

            &:before {
              content: "";
              position: absolute;
              height: 7px;
              width: 15px;
              top: 2px;
              right: -7px;
              margin: -7px 0 0 -6px;
              border: 1px solid #fff;
              border-width: 0 0 1px 1px;
              background: none;
              border-radius: 0 !important;
              transform: rotate(-45deg);
              -moz-transform: rotate(-45deg);
              -webkit-transform: rotate(-45deg);
              -ms-transform: rotate(-45deg);
              -o-transform: rotate(-45deg);
              z-index: 1;
            }
          }

          .customRadio {
            .accordion {
              .card {
                .card-header {
                  background: transparent;

                  &:after {
                    content: "";
                    height: 24px;
                    width: 24px;
                    display: block;
                    background: transparent;
                    border-radius: 50%;
                    position: absolute;
                    right: 0;
                    top: 0;
                  }

                  &:before {
                    content: "";
                    position: absolute;
                    height: 0px;
                    width: 0px;
                    top: 0px;
                    right: 0px;
                    margin: 0;
                    border: 0px solid #fff;
                    border-width: 0 0 1px 1px;
                    background: none;
                    border-radius: 0 !important;
                    transform: rotate(-45deg);
                    -moz-transform: rotate(-45deg);
                    -webkit-transform: rotate(-45deg);
                    -ms-transform: rotate(-45deg);
                    -o-transform: rotate(-45deg);
                    z-index: 1;
                  }
                }

                &.activeShow {
                  border: solid 1px RGB(var(--theme-custom-color));

                  .card-header {
                    background: var(--lighten-theme-custom-color);

                    &:after {
                      content: "";
                      height: 24px;
                      width: 24px;
                      display: block;
                      background: RGB(var(--theme-custom-color));
                      border-radius: 50%;
                      position: absolute;
                      right: -12px;
                      top: -12px;
                    }

                    &:before {
                      content: "";
                      position: absolute;
                      height: 7px;
                      width: 15px;
                      top: 2px;
                      right: -7px;
                      margin: -7px 0 0 -6px;
                      border: 1px solid #fff;
                      border-width: 0 0 1px 1px;
                      background: none;
                      border-radius: 0 !important;
                      transform: rotate(-45deg);
                      -moz-transform: rotate(-45deg);
                      -webkit-transform: rotate(-45deg);
                      -ms-transform: rotate(-45deg);
                      -o-transform: rotate(-45deg);
                      z-index: 1;
                    }
                  }
                }
              }
            }
          }
        }

        &.error {
          border: solid 1px #de132a;

          .card-header {
            background: var(--lighten-theme-custom-color);

            &:after {
              content: "";
              height: 24px;
              width: 24px;
              display: block;
              background: #de132a;
              border-radius: 50%;
              position: absolute;
              right: -12px;
              top: -12px;
            }

            &:before {
              content: "";
              position: absolute;
              height: 7px;
              width: 15px;
              top: 2px;
              right: -7px;
              margin: -7px 0 0 -6px;
              border: 1px solid #fff;
              border-width: 0 0 1px 1px;
              background: none;
              border-radius: 0 !important;
              transform: rotate(-45deg);
              -moz-transform: rotate(-45deg);
              -webkit-transform: rotate(-45deg);
              -ms-transform: rotate(-45deg);
              -o-transform: rotate(-45deg);
              z-index: 1;
            }
          }
        }
      }

      .myForm {
        display: flex;
        flex-wrap: wrap;

        .accEditSave {
          margin-left: -15px;
          margin-right: -15px;
          width: calc(100% - -30px);
          margin-top: -20px;
          margin-bottom: 20px;
          font-size: 14px;
          border-radius: 0px;
          font-family: $SF_TextRegular;
          font-weight: 400;
        }

        .saveAddHeader {
          flex: 1;
          order: -1;
        }
      }

      // .collapsing {
      //     -webkit-transition: none;
      //     transition: none;
      //     display: none;
      //     overflow: auto;
      //     height: auto;
      // }
      &.activeShow {
        border: solid 1px RGB(var(--theme-custom-color));

        > .card-header {
          background: var(--lighten-theme-custom-color);

          &:after {
            content: "";
            height: 24px;
            width: 24px;
            display: block;
            background: RGB(var(--theme-custom-color));
            border-radius: 50%;
            position: absolute;
            right: -12px;
            top: -12px;
          }

          &:before {
            content: "";
            position: absolute;
            height: 7px;
            width: 15px;
            top: 2px;
            right: -7px;
            margin: -7px 0 0 -6px;
            border: 1px solid #fff;
            border-width: 0 0 1px 1px;
            background: none;
            border-radius: 0 !important;
            transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            z-index: 1;
          }
        }

        .editAddHeader {
          display: flex;
          flex: 100%;
        }

        .saveAddHeader {
          display: none;
          opacity: 1;
        }

        .card-body {
          border-top: solid 1px RGB(var(--theme-custom-color));
        }

        .myForm {
          display: flex;
          flex-wrap: wrap;

          .accEditSave {
            margin-left: -15px;
            margin-right: -15px;
            width: calc(100% - -30px);
            margin-top: -20px;
            margin-bottom: 20px;
            font-size: 14px;
            border-radius: 0px;
            font-family: $SF_TextRegular;
            font-weight: 400;
          }

          .saveAddHeader {
            flex: 1;
            order: -1;
          }
        }

        .activeCard {
          border: solid 1px RGB(var(--theme-custom-color));

          > .card-header {
            background: var(--lighten-theme-custom-color);
            color: $black-blue;

            &:after {
              content: "";
              height: 24px;
              width: 24px;
              display: block;
              background: RGB(var(--theme-custom-color));
              border-radius: 50%;
              position: absolute;
              right: -12px;
              top: -12px;
            }

            &:before {
              content: "";
              position: absolute;
              height: 7px;
              width: 15px;
              top: 2px;
              right: -7px;
              margin: -7px 0 0 -6px;
              border: 1px solid #fff;
              border-width: 0 0 1px 1px;
              background: none;
              border-radius: 0 !important;
              transform: rotate(-45deg);
              -moz-transform: rotate(-45deg);
              -webkit-transform: rotate(-45deg);
              -ms-transform: rotate(-45deg);
              -o-transform: rotate(-45deg);
              z-index: 1;
            }
          }
        }
      }

      &.activeButton {
        .card-text {
          .editAdd {
            display: none;
          }
          .saveAdd {
            display: none;
          }
        }
      }
    }
  }

  &.nooverleyText {
    .accordion {
      .card {
        .card-header {
          line-height: 24px;

          p {
            display: -webkit-box;
            -webkit-line-clamp: unset;
            -webkit-box-orient: unset;
            overflow: auto;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

.collapse.show {
  width: 100%;
}

.activeShow .myForm .collapse.show + .saveAddHeader {
  display: block;
  transition: all ease 0.3s;
  opacity: 1;
}

/*
*
*   APP WRAPPER
*
*/

header {
  .headerLogo{
    display: flex;
    .back_icon{
      // margin: auto auto auto 0;
      width: 20px;
      margin: 20px 10px 20px 0px;

      i{
        font-size: 1.25rem;
        color: rgb(var(--theme-custom-color)) !important;
      }
    }
    .logo_One {
      &.backIcon{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
        // margin: 8px 0px 0px 10px;
      }
      margin: 8px 0px ;
      max-width: 100%;
      min-height: 47px;
  
      img {
        // aspect-ratio: 2/1 !important;
        max-height: 47px !important;
        height: auto;
        margin: 0 !important;
      }
  }
  
  }

  .languageIcon {
    .nav-link {
      font-size: 14px;
      font-style: normal;
      font-weight: bold;
      padding-right: 4px;
    }
  }

  .headerDropdown {
    padding-right: 10px;
    margin: 0px 0 0 0;
    .name-alias {
      display: none;
    }

    .dropdown {
      max-width: 130px;
      display: flex;
      .text-default {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        line-height: normal;
      }
      .nav-link {
        font-size: 14px;
        white-space: unset;
        width: auto;
        overflow: auto;
        text-overflow: clip;
        margin-top: 11px;
        padding-left: 0;
        padding-right: 0;
        font-weight: 700;
        text-transform: uppercase;
        color: #be64de !important;
        color: RGB(var(--theme-custom-color)) !important;
        font-family: $SF_TextRegular;

        .avatar {
          display: none;
        }

        .text-default {
          font-size: 14px;
          white-space: unset;
          width: auto;
          overflow: auto;
          margin-top: 14px;
          padding-left: 0;
          padding-right: 0;
          font-weight: 700;
          text-transform: uppercase;
          color: RGB(var(--theme-custom-color)) !important;
          font-family: $SF_TextRegular;

          &.customerDetails{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          &::before {
            display: inline-block;
            margin-left: 0.255em;
            vertical-align: 0.255em;
            content: "";
            border-top: 0.3em solid;
            border-right: 0.3em solid transparent;
            border-bottom: 0;
            border-left: 0.3em solid transparent;
            margin-right: 6.47px;
          }
        }
      }
      .text-default {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        line-height: initial;
      }
    }

    // @media only screen and (min-width: 450px) {
    //     .nav-link {
    //         white-space: unset;
    //         width: auto;
    //         overflow: auto;
    //         text-overflow: initial;

    //         .text-default {
    //             white-space: unset;
    //         width: auto;
    //         overflow: auto;
    //         text-overflow: initial;
    //         }
    //     }
    // }
  }

  .container {
    max-width: 1140px;
    padding: 0 15px;
  }
}

.contentWrapper {
  &.custom-animate {
    width: 100%;
    position: relative;

    &.left {
      display: inline-block;
      position: relative;
      right: -80%;
      transform: translateX(-80%);
      transition: right 1000ms, transform 1s;
    }

    &.right {
      right: 0%;
      transform: translateX(-25%);
      transition-duration: 500ms;
    }

    &.top {
      display: inline-block;
      position: relative;
      bottom: 0%;
      transform: translateY(0%);
    }

    &.bottom {
      bottom: 0%;
      transform: translateY(5%);
      transition-duration: 1000ms;
    }
  }
}

.screen {
  &.phone-screen {
    height: calc(56vh) !important;

    @media only screen and (max-width: 480px) {
      height: calc(65vh) !important;
    }

    @media only screen and (max-width: 380px) {
      height: calc(55vh) !important;
    }
  }

  .container {
    max-width: 411px;
    padding: 0 43px;

    @media only screen and (max-width: 378px) {
      padding: 0 25px;
    }
  }

  .iframe-container {
    max-width: 550px;
    padding: 0% 2%;
    margin: 0% 22%;

    @media only screen and (max-width: 378px) {
      padding: 0 25px;
    }
  }

  .payWith {
    position: sticky;
    bottom: 43px;
    width: 325px;
    left: 43px;
    right: 43px;
    margin: auto;
  }
}

.screenFrame {
  max-width: 411px;
  margin: auto;

  img {
    max-width: 100%;
  }
}

.accountDrop {
  float: right;

  .dropdown-menu {
    float: right;
    min-width: 225px;
    padding: 0;
    margin: 0 0 0 -15px !important;
    border: 1.4px solid RGB(var(--theme-custom-color));
    border-radius: 5.5px;
    right: 0 !important;
    left: auto !important;
    top: 62px !important;
    transform: none !important;

    .dropdown-list {
      margin: 0;
      padding: 0;

      .dropdown-item {
        height: 40px;
        line-height: 40px;
        padding: 0;
        color: $grayBg;
        font-weight: bold;
        border-bottom: solid 0.5px $grayBg;

        .icon {
          margin: 0 15px;
          display: inline-block;
          float: left;
          font-size: 22px;
          text-align: center;
          width: 24px;
          color: $grayBg !important;
        }

        .item-text {
          width: 150px;
          display: inline-block;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          text-transform: uppercase;
        }

        &:last-child {
          border-bottom: none;
        }

        &.active {
          color: RGB(var(--theme-custom-color));
          background: none;

          .icon {
            color: RGB(var(--theme-custom-color)) !important;
          }
        }

        &:hover {
          background: none;
        }
      }

      &:before {
        content: "";
        border-top: 0;
        border-right: 9px solid transparent;
        border-bottom: 13px solid RGB(var(--theme-custom-color));
        border-left: 9px solid transparent;
        position: absolute;
        right: 18px;
        top: -14px;
      }

      &:after {
        content: "";
        border-top: 0;
        border-right: 8px solid transparent;
        border-bottom: 13px solid $white;
        border-left: 8px solid transparent;
        position: absolute;
        right: 19px;
        top: -13px;
      }
    }
  }

  /* .dropdown {

        .btn {
            font-size: 14px;
            white-space: nowrap;
            width: 95px;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-top: 14px;
            padding-left: 0;
            padding-right: 0;
            font-weight: bold;
            text-transform: uppercase;
            color: RGB(var(--theme-custom-color));
            font-family: $SF_TextRegular;
        }

        .dropdown-menu {
            float: right;
            min-width: 225px;
            padding: 0;
            margin-right: 15px;
            margin-top: 10px;
            border: solid 1.4px RGB(var(--theme-custom-color));
            border-radius: 5.5px;

            .dropdown-item {
                height: 40px;
                line-height: 40px;
                padding: 0;
                color: $grayBg;
                font-weight: bold;
                border-bottom: solid .5px $grayBg;

                .icon {
                    margin: 0 15px;
                    display: inline-block;
                    float: left;
                    font-size: 22px;
                    text-align: center;
                    width: 24px;
                }

                .item-text {
                    width: 150px;
                    display: inline-block;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    text-transform: uppercase;
                }

                &:last-child {
                    border-bottom: none;
                }

                &.active {
                    color: RGB(var(--theme-custom-color));
                    background: none;
                }

                &:hover {
                    background: none;
                }
            }

            &:before {
                content: "";
                border-top: 0;
                border-right: 9px solid transparent;
                border-bottom: 13px solid RGB(var(--theme-custom-color));
                border-left: 9px solid transparent;
                position: absolute;
                right: 18px;
                top: -14px;
            }

            &:after {
                content: "";
                border-top: 0;
                border-right: 8px solid transparent;
                border-bottom: 13px solid $white;
                border-left: 8px solid transparent;
                position: absolute;
                right: 19px;
                top: -13px;
            }
        }

        .dropdown-toggle {
            &:before {
                display: inline-block;
                margin-left: 0.255em;
                vertical-align: 0.255em;
                content: "";
                border-top: 0.3em solid;
                border-right: 0.3em solid transparent;
                border-bottom: 0;
                border-left: 0.3em solid transparent;
            }

            &:after {
                content: none;
            }
        }
    }*/
}

.cartView{
  h1{
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .cartViewSmallText{
    // margin-bottom: 15px !important;
    margin-bottom: 10px !important;
  }
}

.signInView {
  text-align: center;

  h1 {
    font-family: $SF_ProDisplay;
    color: $black-blue;
    font-size: 48px;
    margin-bottom: 10px;
    margin-top: 30px;
  }

  .signviewPtext {
    font-size: 20px;
    margin-bottom: 25px;
    color: $mid-grey;
  }

  .signviewsmalltext {
    font-size: 14px;
    margin-bottom: 20px;
    color: $mid-grey;

    span {
      color: $black-blue;
    }
  }

  .Otptext {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 30px;
    color: $mid-grey;
    max-width: 280px;
    margin-left: auto;
    margin-right: auto;

    .num {
      color: $black-blue;
    }

    a {
      color: $black-blue;
    }
  }

  .notCode {
    font-size: 14px;
    font-weight: 300;
    color: $mid-grey;
    margin-bottom: 25px;

    a {
      color: $black-blue;
      text-decoration: underline;
    }
  }

  .myForm {
    font-family: $SF_TextRegular;

    .formInput {
      margin-bottom: 25px;
    }
  }

  .another-num{
    font-weight: 400;
    font-size: 14px;
    color: RGB(var(--theme-custom-color));
    text-align: end;
    padding-top: 5px;
    cursor: pointer;
  }

  .optionBtn {
    margin-top: 35px;
    margin-bottom: 40px;
    margin-left: -10px;
    margin-right: -10px;

    .btn {
      margin: 0 3px;
      // margin: 0 10px;
    }
  }

  .address-optionBtn {
    margin-top: 0px !important;
  }

  .singleBtn {
    margin-top: 35px;
    margin-bottom: 40px;
  }

  .address-singleBtn {
    margin-top: 0px !important;
  }

  .continueBtn {
    .btn {
      margin-bottom: 15px;
    }
  }

  .ContNum {
    font-family: $SF_ProDisplay;
    font-size: 24px;
    color: RGB(var(--theme-custom-color));
    margin-bottom: 30px;
  }

  .oneTapHeading {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

.OtpNum {
  .OTPPin {
    .OTPPinNo {
      display: flex;
      position: relative;
      border: solid 1px $mid-grey;
      height: 50px;
      padding-left: 20px;
      padding-right: 19px;
      border-radius: 5px;

      > div > div {
        margin: 3px !important;
      }

      input {
        font-family: $SF_TextRegular !important;
        width: 100% !important;
        text-align: center;
        border: none !important;
        background: white;
        position: relative;
        font-size: 24px !important;
        outline: 0 none;
        box-shadow: none !important;
      }

      &:before {
        content: " ";
        width: 80%;
        height: 2px;
        position: absolute;
        left: 10%;
        right: 10%;
        background: $grayCol;
        top: 0;
        bottom: 0;
        z-index: 0;
        margin: auto;
      }
    }
  }
}

.CheckoutOtpNum {
  .OTPPin {
    .OTPPinNo {
      display: flex;
      position: relative;
      border: solid 1px $mid-grey;
      height: 50px;
      padding-left: 20px;
      padding-right: 19px;
      border-radius: 5px;

      input {
        font-family: $SF_TextRegular !important;
        width: 7% !important;
        text-align: center;
        border: none !important;
        background: white;
        position: relative;
        margin: 3px !important;
        font-size: 16px !important;
        outline: 0 none;
        box-shadow: none !important;
      }

      &:before {
        content: " ";
        width: 80%;
        height: 2px;
        position: absolute;
        left: 10%;
        right: 10%;
        background: $grayCol;
        top: 0;
        bottom: 0;
        z-index: 0;
        margin: auto;
      }
    }
  }
}

.map {
  position: relative;
  padding-top: 0 !important;

  iframe {
    border: none;
  }

  .mappinbtn {
    position: absolute;
    right: 10px;
    bottom: 20px;

    .btn-primary {
      color: #ffffff;
    }

    .icomoon-review-address {
      margin-right: 5px;
      font-size: 13px;
      vertical-align: sub;
    }
  }
}

.ccScreen {
  position: relative;
  padding-top: 0 !important;
  margin-bottom: 20px;
  text-align: center;

  .ccScreenbtn {
    position: absolute;
    right: 10px;
    bottom: 20px;

    .btn-primary {
      color: #ffffff;
    }

    .icon-icon-camera {
      margin-right: 5px;
      font-size: 13px;
      vertical-align: sub;
    }
  }
}

.midWrap {
  /*height: calc(100vh - 255px);*/
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  .midView {
  }
}
.checkoutDetails{
  margin-top: 1rem;

}
.checkOutInfo {
  text-align: left;
  border-bottom: solid 1px RGB(var(--theme-custom-color));
  padding: 0px 7px 0px 0;

  @media only screen and (max-width: 600px) {
    // margin-bottom: 18px !important;
  }
  .checkOutInfoBox {
    display: flex;
    align-items: flex-start;
    // margin-bottom: 25px;
    // margin-bottom: 20px;
    margin-bottom: 7px;

    .iconCheckout {
      font-size: 20px;
      width: 30px;
      margin-right: 10px;
      flex: none;
      color: RGB(var(--theme-custom-color));
      text-align: center;

      .icomoon-review-address {
        font-size: 20px;
      }
    }

    .checkOutDetail {
      flex: auto;
      font-size: 20px;

      .top {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .head {
          line-height: 22px;
          margin-bottom: 7px;
          color: $black-blue;
        }

        .edit {
          font-size: 13px;
          text-transform: uppercase;
          color: RGB(var(--theme-custom-color));
          font-weight: 600;
          background: none;
          border: none;
          padding: 0;
          outline: 0 none;
          cursor: pointer;
        }
      }

      .main {
        line-height: 24px;
        color: $mid-grey;
        .review-address{
          word-break: break-word;
          display: -webkit-box !important;
          -webkit-line-clamp: 3 ;
          -webkit-box-orient: vertical;
          overflow: hidden;
          margin-bottom: 3px;
        }
        

          .companyName {
            width: 100%;
            /* Body-Regular / HL All Caps 1 - 14PX - B */
            font-family: SF Pro Text;
            font-style: normal;
            font-weight: bold;
            font-size: 12.9855px;
            line-height: 88.4%;
            /* or 12px */
            text-transform: uppercase;
            /* Secondary/Blackish Blue */
            color: #192631;
            margin-bottom: 3px;
          }
          .subhead-detail {
            margin-bottom: 3px;
          }

          .subhead-outline{
            padding: 10px 6px;
            border: 1.5px solid #B3B3B3;
            box-sizing: border-box;
            border-radius: 4px;
            margin-bottom: 10px;
            .edit {
              font-size: 13px;
              text-transform: uppercase;
              color: RGB(var(--theme-custom-color));
              font-weight: 600;
              background: none;
              border: none;
              padding: 0;
              outline: 0 none;
              cursor: pointer;
            }
            .information.information-icon{
              margin-left: 0px !important;
            }
          }
        .subhead {
          font-weight: 600;
          font-size: 15px;
          display: flex;
          justify-content: space-between;
          align-items: center;


          .icon {
            line-height: 0px;
            // width: 42px;
            text-align: center;
            margin: 0px;

            img {
              // max-width: 4rem;
              // max-width: 45px;
              max-height: 43px;
              object-fit: cover;
            }
          }

          .information-icon {
            // line-height: 4px;
            margin-left: 5px !important;
            margin-right: 2px;
          }

          .information {
            width: 100%;
            // width: 235px;
            text-align: left;
            margin-left: 0px;
          }

          .childhead {
            font-weight: 600;
            font-size: 15px;
            margin-bottom: 3px;
            color: $grayCol;

            span {
              line-height: 0px;
              font-size: 15px;
              text-transform: uppercase;
              color: RGB(var(--theme-custom-color));
              font-weight: 600;
              background: none;
              border: none;
              padding: 0;
              outline: 0 none;
              float: right;
              cursor: pointer;

              .MuiIconButton-root {
                margin-left: 20px;
                padding: 0px;
              }
            }
          }

          &.cvv {
            .MuiFormControl-marginNormal {
              margin-top: 0 !important;
              margin-bottom: 0 !important;
            }
          }
        }

        p {
          margin-bottom: 0;
          &.error {
            font-size: 12px;
            line-height: 14px;
            color: #f44336;
            font-size: 12px;
          }
        }
      }
    }

    &.hide {
      display: none;
    }
  }
}
.toolTipAnchor {
  background: none;
  color: RGB(var(--theme-custom-color));
  border: 0;
  text-decoration: underline;
}
.cartItemSection {
  text-align: left;
  border-bottom: solid 1px RGB(var(--theme-custom-color));
  padding: 0px 7px 0px 0;
  // margin-bottom: 30px;
  margin-bottom: 15px;

  .cartItemBox {
    display: flex;
    align-items: flex-start;
    // margin-bottom: 25px;
    margin-bottom: 10px;

    .cartImage {
      position: relative;
      border: solid 1px RGB(var(--theme-custom-color));
      border-radius: 3px;
      height: 80px;
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      // width: 64px;
      padding: 4px;
      box-shadow: 0px 2px 4px 1px rgba(var(--themes-custom-color), 0.2);

      img {
        margin: 0px;
        width: 60px;
        object-fit: cover;
        height: 53px;
      }
    }

    .cartInfo {
      width: 75%;

      .cartProductNameClass {
        p {
          text-overflow: ellipsis;
          /* Needed to make it work */
          overflow: hidden;
          white-space: nowrap;
        }
      }

      .cartVariations {
        display: -webkit-box;
        // -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        // overflow: hidden;
        font-style: normal;
        font-weight: 600;
        font-size: 13.7982px;
        line-height: 20px;
        color: RGB(var(--theme-custom-color));
        // margin-bottom: 12px;
      }
      
      .cartProductStore{
        .storeInfo{
          align-items: center;
          .store-name {
            width: 100% !important;
            font-weight: 600;
            font-size: 12px;
            margin-left: 6px;
            line-height: 20px;
            -webkit-line-clamp: 1;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
          }
          .store-icon {
            max-width: 23px;
            max-height: 23px;
            width: 100% !important;
            margin: 0 auto !important;
            img{
              border-radius: 50%;
              padding: 2px;
              border: 1px solid;
              border: solid 1px RGB(var(--theme-custom-color));
            }
          }
        }
      }
      .cartProductName {
        width: 100%;

        p {
          font-weight: 600;
          font-size: 15px;
          line-height: 20px;
          -webkit-line-clamp: 2;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          // margin-top: 3px;
          margin-bottom: unset;
        }

        div {
          margin: 0 2px;
          font-family: $SF_TextRegular;
          font-style: normal;
          font-weight: 500;
          font-size: 14.0438px;
          line-height: 19px;
          /* Secondary / Light Grey */
          color: #bdc2c4;
          
          -webkit-line-clamp: 4;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          margin-bottom: unset;

          span {
            font-family: $SF_TextRegular;
            font-style: normal;
            font-weight: 600;
            font-size: 14.0438px;
            line-height: 19px;
            /* Secondary / Light Grey */
            color: #b9c0c4;
          }
        }

      }
      .cartInfoAddNotes {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: 7px;
        font-style: normal;
        font-weight: 600;
        font-size: 11.7982px;
        line-height: 20px;
        color: RGB(var(--theme-custom-color));
      }
      .cartInfoBottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: RGB(var(--theme-custom-color));

        .qty {
          font-weight: 600;
          font-size: 15px;
          line-height: 20px;
        }

        .price {
          margin-top: 0.2rem;
          font-size: 20px;
          line-height: normal;
        }

        .slideProdPrice {
          span {
            font-style: normal;
            font-weight: bold;
            font-size: 15px;
            line-height: 88.4%;
            color: RGB(var(--theme-custom-color));

            &:first-child {
              margin-right: 5px;
            }
          }
          // &.newPrice{
          //     margin-bottom: 17px;
          // }
          &.strikeText {
            text-decoration: line-through;
            color: rgb(var(--theme-custom-color));
          }
          &.suggestPrice {
            text-align: right;
            span {
              color: rgb(var(--lighten-theme-custom-color));
              font-size: 15.1041px;
            }
          }
        }
      }
    }
  }
}
.voucher {
  text-align: left;
  margin-bottom: 10px;
  .btn-voucher{
    padding: 0px 16px 0px 7px;
    width: auto;
    font-size: 14px;
    text-transform: uppercase;
  }

  .MuiFormControl-root {
    // margin-bottom: 15px;
    margin-bottom: 0px;
  }

  &.cnicRequirement{
    .MuiFormControl-root {
      margin-top: 15px !important;
    }
  }
  .MuiFormLabel-root.Mui-focused {
    color: RGB(var(--theme-custom-color)) !important;
  }
  .dialog{
    background: #FFFFFF;
    /* Grey / Tints / Disabled */

    border: 1.04891px solid #E6E6E6;
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5.53826px;
    // height: 90px;
    overflow-y: scroll;
    overflow-x: hidden;
    
    .vouchRedeemed {
      padding-top: 8px;

      &.receiptInfo {
        .receiptSub {
          margin: 0 20px;
          display: flex;
          justify-content: space-between;
          // border-bottom: solid 1px $mid-grey;
          margin-bottom: 5px !important;

          .right {
            width: 20%;
            text-align: right;
            color: #91989c;
            width: 50%;
          }

          .left {
            width: 80%;
            div {
              position: absolute;
              display: contents;

              p {
                background: RGB(var(--theme-custom-color));
                border-radius: 2.25843px;
                color: #fff;
                display: inline-flex;
                width: 80%;
                margin-bottom: 3px !important;

                span {
                  vertical-align: -0.125em;
                  color: #fff;
                  margin-left: 0.75rem;
                }

                .discount-tag {
                  color: var(--lighten-theme-custom-color);
                  margin: 0.25rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

.receiptSection {
  position: relative;
  text-align: left;
  margin-bottom: 60px;
  padding-bottom: 15px;
  padding-left: 8px;
  padding-right: 8px;

  .topPattern {
    top: -16px;
    position: absolute;
    width: 100%;
    left: 0;

    svg {
      fill: var(--lighten-theme-custom-color);
    }
  }

  .bottomPattern {
    bottom: -18px;
    position: absolute;
    width: 100%;
    left: 0;

    svg {
      fill: var(--lighten-theme-custom-color);
    }
  }

  .top {
    padding-bottom: 10px;
    border-bottom: solid 1px $mid-grey;
    margin-bottom: 15px;
    text-align: center;

    img {
      max-height: 49px;
    }
  }

  .receiptInfo {
    font-size: 16px;
    color: $navyblue;
    padding: 5px;
    padding-bottom: 0px;
    margin-bottom: 10px;

    .receiptSub {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;

      .left {
      }

      .right {
      }
    }

    &.receiptServiceCharge {
      justify-content: space-between;
      align-items: flex-end;
      border-top: solid 1px #91989c;
      padding-top: 15px;
      color: $black-blue;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }

    .receiptSubItems {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
      font-weight: 300;
      font-size: 16px;
      line-height: 20px;
      color: $mid-grey;
      margin-left: 1.5rem;

      .left {
        font-size: 14px;
      }

      .right {
        font-size: 14px;
      }
    }
  }

  .storeInfo{
    // border: 1px solid $mid-grey;
    margin: 2px 0 7px 0;
    // padding: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 0px;
    box-shadow: 0px 2px 5px rgb(60 66 87 / 8%), 0px 0px 0px 1px rgb(60 66 87 / 16%), 0px 1px 1px rgb(0 0 0 / 12%);
    padding: 2px 5px;
    .store-name {
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
      -webkit-line-clamp: 2;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
    .store-icon {
      // max-width: 27px;
      // max-height: 27px;
      max-width: 30px !important;
      max-height: 30px !important;
      width: 100% !important;
      img{
        border-radius: 50%;
        padding: 2px;
        border: 1px solid;
        border: solid 1px RGB(var(--theme-custom-color));
      }
    }
  }
  
  .receiptDiscounts {
    border-top: solid 1px #91989c;
    // padding-top: 15px;
    padding-top: 8px;
    color: $black-blue;

    .receiptInfo {
      .receiptSub {
        // margin-bottom: 0.25rem;
        margin-bottom: 0.7rem;
        .right {
          text-align: right;
          color: #91989c;
          width: 50%;
        }

        .left {
          div {
            position: absolute;
            display: contents;

            p {
              height: 100%;
              background: RGB(var(--theme-custom-color));
              border-radius: 2.25843px;
              color: #fff;
              /*padding-left: 0.5rem;
                            padding-right: 0.5rem;*/
              display: inline-flex;
              width: 80%;

              span {
                vertical-align: -0.125em;
                color: #fff;
              }

              .discount-tag {
                color: var(--lighten-theme-custom-color);
                margin: 0.25rem;
                content: url("https://api.iconify.design/icomoon-free:price-tags.svg?color=%23EBF5FF&height=16");
              }

              .close-discount {
                svg {
                  color: #fff;
                  font-size: 17px;
                  position: relative;
                  top: 50%;
                  -webkit-transform: translateY(-50%);
                  -ms-transform: translateY(-50%);
                  transform: translateY(-50%);
                }
              }
            }

            .discount-pricing {
              height: 100%;
              color: RGB(var(--theme-custom-color));
              // width: 50% !important;
              font-family: $SF_ProDisplay !important;
              font-style: normal;
              font-weight: bold;
              // font-size: 0.75rem;
              font-size: 0.66rem;
              display: block;
              padding-top: 0.5rem;
              // padding-left: 0.4rem;
              line-height: 88.4%;
              text-transform: uppercase;
              /*color: #137BDE;*/
              &.dropdown-discount-pricing {
                flex: none;
              }
            }
          }
        }
      }
    }
  }

  .receiptTotal {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-top: solid 1px $mid-grey;
    padding-top: 15px;
    padding-bottom: 10px;
    color: $black-blue;
    margin-bottom: 0 !important;

    .total {
      font-size: 20px;
    }

    .totalPrice {
      font-family: $SF_ProDisplay;
      font-size: 24px;
      font-weight: 900;
    }
  }
}

.termCondition {
  margin: 0px 0px 2rem 0px;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 2rem !important;
  &.termsAdditionalHeader {
    border-top: solid 1px RGB(var(--theme-custom-color));
    padding: 18px 0px 0px 0;
  }
  .copyRightsIcon {
    display: flex;
    align-items: center;
    width: 22%;
    @media (max-width:340px) {
      width: 27%;
    }
  }
  .terms {
    border-left: solid 1px RGB(var(--theme-custom-color));
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color:  $mid-grey;
    text-align: left;
    width: 73%;
    margin-left: 0.25rem;
    padding-left: 1rem;
    @media (max-width:340px) {
      width: 68%;
    }
  }
  @media only screen and (max-width: 600px) {
    margin: 19px 0 1.75rem 0;
  }
  a {
    color: RGB(var(--theme-custom-color));
  }

  button {
    border: 0;
    color: RGB(var(--theme-custom-color));
    padding: 0;
    margin: 0;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    text-transform: unset;

    &:hover {
      background: none;
      color: RGB(var(--theme-custom-color));
      border: 0;
      text-decoration: underline;
    }

    &:focus {
      background: none;
      color: RGB(var(--theme-custom-color));
      border: 0;
      text-decoration: underline;
      box-shadow: 0 0 0;
      outline: none;
    }
  }
}

.bottomFixedBar {
  position: fixed;
  z-index: 999;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: 50px;
  width: 100%;
  &.footerReviewFixed {
    min-height: 110px;
    height: auto;
    .footerReview {
      width: 100%;
      line-height: 100%;
      margin: 0px;
      margin: 10px 0 0px 0px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      span {
        &.mainfooterReviewText {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          font-size: 14px;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          padding: 0 10px;
          width: 100%;
          display: flex;
          align-items: inherit;

          .MuiIconButton-colorPrimary {
            color: $white;
            padding: 0;
            font-size: 16px;
          }
          .subFooterReviewIcon {
            width: 15%;
            color: #ffffff;
            color: $white;
            font-size: 16px;
            justify-content: inherit;
            top: 50%;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
          }
          .subFooterReviewText {
            text-align: left;
            padding: 0 10px 0 10px;
          }
        }
      }
    }
    &.totalFixed {
      // height: 170px;
      // .footerReview
      // {
      //     margin-top: 0px;
      // }
    }
    @media only screen and (max-width: 500px) {
      height: auto;
      .footerReview {
        margin-top: 10px;
        margin-bottom: 10px;
      }
      &.totalFixed {
        height: 200px;
        .footerReview {
          margin-top: 5px;
        }
      }
    }
  }
}

.bottom-padding {
  padding-bottom: 130px;

  &.btm-170 {
    padding-bottom: 170px;
  }
  &.btm-200 {
    padding-bottom: 200px;
  }
}

.totalFixed {
  min-height: 136px;
  background: RGB(var(--theme-custom-color));
  color: $white;
  padding: 10px 0;

  .receiptTotal {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 12px 0;

    .total {
      font-size: 20px;
    }

    .totalPrice {
      font-family: $SF_ProDisplay;
      font-size: 32px;
      font-weight: 900;
      line-height: normal;
    }
  }

  .btn-outline-primary {
    color: RGB(var(--theme-custom-color)) !important;

    span {
      font-size: 20px;
    }

    &:hover {
      // border-color: $white;
      // color: #fff !important;
      background: white;
    }

    &:focus {
      // border-color: $white;
      // color: #fff !important;
      background: white;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active .btn-outline-primary:active {
      color: #ffffff !important;
      background: var(--lighten-theme-custom-color);
      border-color: #ffffff;
    }
  }

  .clickedBtn {
    // border-color: $white;
    // color: #fff !important;
    // background: transparent;
  }

  .notClickedBtn {
    border-color: $white;
    color: RGB(var(--theme-custom-color)) !important;
    background: #fff;

    &:active {
      border-color: $white !important;
      color: RGB(var(--theme-custom-color)) !important;
      background: #fff !important;
    }
  }
}

.MuiFormControl-marginNormal {
  margin-top: 0 !important;
  margin-bottom: 25px;
}

.cnicField {
  margin-top: 10px !important;
}

.MuiFormControl-root {
  margin-bottom: 25px;
}

.msgfieldBot {
  margin-bottom: 0px !important;
}

.MuiFormLabel-root.Mui-focused {
  color: RGB(var(--theme-custom-color)) !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: RGB(var(--theme-custom-color)) !important;
  border-width: 1px !important;
}

.MuiInputBase-input {
  font-family: $SF_TextRegular;
}

// .Mui-disabled {
//     background: $grayBg;
// }

.MuiDialog-scrollPaper {
  align-items: flex-end !important;
}

.MuiDialog-paperWidthSm {
  width: 100% !important;
  max-height: 300px !important;
  margin: 0 !important;
  border-radius: 0px !important;
  max-width: 100% !important;
  background: RGB(var(--theme-custom-color)) !important;
  color: #fff !important;
  text-align: center;
  &.TermsPopup,
  .PrivacyPopup {
    text-align: left;
    max-height: 350px;
  }
}

.MuiTypography-h6 {
  font-size: 24px !important;
  font-family: $SF_ProDisplay !important;
  font-weight: 500;
  line-height: 28px !important;
  letter-spacing: 0.0075em;
}

.MuiTypography-body1 {
  font-weight: 300 !important;
  font-size: 14px !important;
  font-family: $SF_TextRegular !important;
  // color: #fff !important;
}

.MuiDialogContent-root {
  overflow: auto !important;
  margin-bottom: 10px;
}

.MuiDialogTitle-root {
  padding: 25px 24px 10px !important;
}

.MuiDialogContentText-root {
  margin-bottom: 0 !important;
}

.MuiSelect-select:focus {
  background: none !important;
  outline: 0 none !important;
}

.closeright {
  position: absolute !important;
  right: 0;
  top: 0;
  color: white !important;

  &:hover {
    border: none !important;
  }
}

.MuiInputBase-formControl {
  .MuiSelect-iconOutlined {
    background: url(cdnUrl("checkout_app/assets/images/icon-caret-down.png"))
      50% 50% no-repeat;

    path {
      display: none !important;
    }
  }
}

.twoField {
  display: flex;
  flex-wrap: wrap;
  &.ccGeneral {
    margin: 0 -12px !important;
  }

  .MuiFormControl-marginNormal {
    margin: 0 0;
  }

  .MuiInput-underline {
    border: none;

    &:focus {
      border: none;
    }
  }

  .MuiInput-underline:hover:not(.Mui-disabled)::before {
    border: solid 1px $black-blue;
  }

  .MuiInput-underline:hover:not(.Mui-disabled)::after {
    border: solid 1px RGB(var(--theme-custom-color));
  }

  .MuiInput-underline::before {
    left: 0;
    right: 0;
    height: 100%;
    bottom: 0;
    content: "\00a0";
    position: absolute;
    transition: border ease 0.05s;
    border: 1px solid $grayCol;
    border-radius: 4px;

    &:hover {
      border: solid 1px $black-blue;
    }
  }

  .MuiInput-underline::after {
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    content: "";
    position: absolute;
    transform: scaleX(0);
    transition: border ease 0.05s;
    border: 1px solid RGB(var(--theme-custom-color));
    pointer-events: none;
    border-radius: 4px;
  }

  .MuiGrid-container {
    label + .MuiInput-formControl {
      margin-top: 0;
      // height: 100%;
    }

    .MuiInputLabel-formControl {
      transform: translate(0, 20px) scale(1);
    }

    .MuiInputLabel-shrink {
      transform: translate(10px, -6px) scale(0.75);
      transform-origin: top left;
      background: white;
      z-index: 1;
      padding: 0 7px;
    }
  }

  .MuiInputBase-input {
    padding: 18.5px 0 17px 18.5px;
  }

  .MuiFormControl-root {
    &.MuiTextField-root {
      &.col-md-6 {
        flex: 0 0 46.8%;
        max-width: 46.8%;
      }
    }
  }
  .cvvIcon {
    padding-left: 0rem !important;
  }

  .cvv {
    .MuiInputBase-formControl {
      flex-direction: row-reverse;
      padding-left: 0;
    }

    .MuiInputAdornment-positionEnd {
      margin-right: 10px;
    }

    .MuiInputLabel-outlined {
      transform: translate(18px, 19px) scale(1);
      background: #fff;
      padding: 0 3px;
    }

    .MuiInputLabel-outlined.Mui-focused {
      transform: translate(14px, -6px) scale(0.75);
    }

    .PrivateNotchedOutline-root-1 {
      top: 0;
    }

    .PrivateNotchedOutline-legendNotched-4 {
      display: none;
    }
  }

  @media only screen and (max-width: 767px) {
    margin: 0px !important;

    .MuiFormControl-root.MuiTextField-root.col-md-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .MuiGrid-justify-xs-space-around {
      padding: 0;
    }
  }

  &.creditCardDiv {
    .MuiGrid-root:first-child {
      .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
        display: none;
      }

      .MuiFormControl-root {
        .MuiInputLabel-formControl {
          transform: translate(19px, 20px) scale(1);

          &.MuiInputLabel-shrink {
            transform: translate(10px, -6px) scale(0.75);
          }
        }
      }
    }

    &.cvvInnerButton {
      .MuiIconButton-colorPrimary {
        right: 0;
        color: RGB(var(--theme-custom-color));
        // padding: 12px 10.5px !important;
        padding: 0px !important;
        position: absolute;
        top: 40%;
        -ms-transform: translateY(-50%);
        transform: translateY(-40%);

        .MuiSvgIcon-root {
          fill: currentColor;
          width: 1em;
          height: 1em;
          display: inline-block;
          font-size: 1.5rem;
          transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          flex-shrink: 0;
          user-select: none;
        }
      }

      .MuiIconButton-colorPrimary:focus {
        outline: 0px auto -webkit-focus-ring-color !important;
        outline: none !important;
      }
    }
  }
}

.MuiDialog-paperScrollPaper {
  .MuiPickersCalendarHeader-switchHeader {
    .MuiTypography-body1 {
      font-size: 14px !important;
    }
  }

  .MuiPickersCalendarHeader-dayLabel {
    color: #fff !important;
  }

  .MuiPickersDay-day {
    color: #fff !important;
  }

  .MuiPickersCalendarHeader-switchHeader {
    margin-bottom: 20px;
  }
}

.MuiDialog-paper {
  .MuiDialogActions-root {
    .MuiButton-textPrimary {
      &:first-child {
        color: $white;
        border: solid 1px $white;
      }

      &:last-child {
        background: $white;
        border: solid 1px $white;
      }
    }
  }
}

.MuiButtonBase-root.MuiButton-root.MuiButton-text.btn.btn-white.btn-white-B.MuiButton-textPrimary {
  color: RGB(var(--theme-custom-color));
  padding: 6px 16px;
}

.checkBoxCard {
  margin: 0px 0 0 0;
  text-align: left;

  .Mui-disabled {
    background: transparent;

    .MuiFormControlLabel-label {
      color: #5f666a;
    }

    .MuiIconButton-colorPrimary {
      .MuiIconButton-label {
        svg {
          fill: $grayCol;
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  .MuiIconButton-colorPrimary {
    .MuiIconButton-label {
      svg {
        fill: RGB(var(--theme-custom-color));
        width: 24px;
        height: 24px;
      }
    }
  }
  .PrivateSwitchBase-root-1 {
    padding: 4px;
  }
  .MuiFormControlLabel-label {
    font-family: $SF_ProDisplay;
    font-style: normal;
    align-items: center;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 12px !important;
    display: flex;
    letter-spacing: 0em !important;
    word-spacing: 0em !important;
    color: #5F666A !important;
  }
}

@media only screen and (max-width: 410px) {
  .signInView {
    h1 {
      font-size: 36px;
      margin-top: 30px;
    }

    .signviewPtext {
      font-size: 16px;
    }

    .signviewboldsmalltext {
      font-size: 15px;
      font-weight: 800;
      color: #0f5f50;

      &.bold {
        font-weight: 1000;
        font-style: oblique;
      }
    }

    .signviewsmalltext {
      font-size: 11px;
    }

    .notCode {
      font-size: 12px;
    }

    .btn-outline-primary {
      font-size: 14px;
    }

    .btn-primary {
      font-size: 14px;
    }

    .dropInput {
      .input-group {
        .react-custom-flag-select__select__button___15AP2 {
          font-size: 16px;
          padding: 11px;
        }
      }

      .error {
        border: solid 1px #f44336;
      }
    }
  }

  .oneTap {
    font-size: 24px !important;
  }

  .topStatus {
    height: 65px;

    a {
      .icon {
        height: 28px;
        width: 28px;
      }

      .statusText {
        font-size: 16px;
      }
    }
  }

  .font18 {
    font-size: 14px;
  }

  .stepStatus {
  }

  .checkOutInfo {
    .checkOutInfoBox {
      .checkOutDetail {
        font-size: 16px;

        .top {
          .head {
            margin-bottom: 5px;
          }

          .edit {
            font-size: 13px;
          }
        }
      }
    }
  }

  .cartItemSection {
    .cartItemBox {
      .cartInfo {
        .cartProductName {
          p {
            font-size: 13px;
            // margin-bottom: 6px;
          }
        }
        .cartProductNameClass {
          p {
            text-overflow: ellipsis;
            /* Needed to make it work */
            overflow: hidden;
            white-space: nowrap;
          }
        }

        .cartInfoBottom {
          .qty {
            font-size: 13px;
          }

          .price {
            margin-top: 0.2rem;
            font-size: 17px;
          }
        }
      }
    }
  }

  .receiptSection {
    .receiptInfo {
      font-size: 16px;
    }

    .receiptTotal {
      .total {
        font-size: 18px;
      }

      .totalPrice {
        font-size: 22px;
        line-height: 25px;
      }
    }
  }


  .totalFixed {
    .receiptTotal {
      .totalPrice {
        font-size: 28px;
      }
    }
  }

  .customRadio {
    .accordion {
      .card {
        .card-header {
          font-size: 15px;
        }

        .card-body {
          font-size: 12px;
        }
      }
    }
  }

  .screen {
    .payWith {
      width: 285px;
    }
  }
}

.zero-padding {
  padding: 0px;
}
.emoji {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .review {
    flex: 100%;
    font-size: 20px;
    margin-bottom: 20px;
    color: $black-blue;
  }
}

.paidStamp {
  background: url(cdnUrl("checkout_app/assets/images/paidStamp.png")) 100%
    no-repeat;
  height: 96px;
  width: 96px;
  position: absolute;
  right: 0;
  top: -35px;
}

.MuiDialogActions-root {
  justify-content: center !important;

  .btn-white {
    color: RGB(var(--theme-custom-color));
    background-color: $white;
    border: solid 1px $white;
    font-size: 13px;

    &:hover,
    &:focus {
      color: RGB(var(--theme-custom-color));
      background-color: $white;
      border: solid 1px $white;
    }
  }

  .btn-outline-white {
    border: solid 1px $white;
    background: RGB(var(--theme-custom-color));
    color: $white;
    font-size: 13px;

    &:hover {
      background: $white;
      border: solid 1px $white;
      color: RGB(var(--theme-custom-color));
    }

    &.disabled {
      cursor: not-allowed;
      background: none;
      color: $grayCol;
      border-color: $grayCol;
    }
  }
}

// .cardSearch {
//     background: url(cdnUrl("images/assets/cardSearch.png")) 100% no-repeat;
//     height: 126px;
//     width: 100%;
//     display: block;
//     margin-bottom: 20px;
// }

.MuiPickersBasePicker-container {
  justify-content: center;
  align-items: center;
}

.MuiDialogContent-root {
  margin-top: 10px;
}

.MuiInputBase-root.Mui-disabled {
  // .MuiOutlinedInput-notchedOutline {
  //     border: none;
  // }

  .MuiInputBase-input.Mui-disabled {
    cursor: not-allowed;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

// .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
//     display: none !important;
// }

.MuiFormLabel-root.Mui-disabled {
  // display: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.newCustomRadio {
  .card-text {
    margin: 0;
  }
}

.newContinueBtnWrap {
  button {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.MuiDialog-paper
  .MuiDialogActions-root
  .MuiButton-textPrimary:first-child:hover {
  color: RGB(var(--theme-custom-color)) !important;
}

.icon-ship-custom {
  /*background: url(cdnUrl("assets/images/ship.svg")) no-repeat;*/
  width: 20px;
  height: 20px;
  display: inline-block;
}

.mtop20 {
  margin-top: 20px !important;
}

.processingWrap {
  height: 99vh;
}

.editMedia {
  .icon {
    width: 26px;
  }

  .address-body{
    max-width: 94%;
  }

  .trashIcon {
    margin-top: 10px;
    margin-left: -15px;
    width: 36px;
    &.disabled{
      pointer-events: none;
      color: #91989c !important;
      .icomoon-icon-trash {
        pointer-events: none;
        color: #91989c !important;
      }
    }

    .icomoon-icon-trash {
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      text-transform: uppercase;
      letter-spacing: 0.3px;
      font-size: 20px !important;
      color: RGB(var(--theme-custom-color));
    }
  }

  p {
    font-size: 14px;
  }

  span {
    font-weight: 600;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    color: $mid-grey;
    line-height: 1px;
  }
}

.errorPage {
  background: var(--lighten-theme-custom-color);
  height: calc(100vh - 3px);
  display: flex;

  .errorPageLogo {
    background: var(--lighten-theme-custom-color);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ebf2f9', endColorstr='#c6d7eb', GradientType=1);
    box-shadow: 25.6842px 6.42105px 102.737px rgba(18, 61, 101, 0.3),
      inset 1.8421px 1.63158px 18.421px rgba(255, 255, 255, 0.4);
    width: 226px;
    height: 100%;
    margin-left: 160px;
    position: relative;

    img {
      width: 100px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }

    @media only screen and (max-width: 768px) {
      width: 194px;
      margin-left: 40px;

      img {
        width: 80px;
      }
    }

    @media only screen and (max-width: 600px) {
      width: 70%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 30px;
      margin-bottom: 30px;
      height: 100px;
      border-radius: 20px;

      img {
        width: 60px;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: center;
  }

  .errorPageText {
    margin: auto 60px;

    h1 {
      font-size: 50px;
      font-family: $SF_ProDisplay;
      color: RGB(var(--theme-custom-color));
      margin-bottom: 8px;
    }

    p {
      font-size: 34px;
      font-family: $SF_ProDisplay;
      margin-bottom: 15px;
      line-height: 40px;
    }

    .btn-error-back {
      background: var(--darken-lighten-theme-custom-color);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ebf2f9', endColorstr='#c6d7eb', GradientType=1);
      box-shadow: -25.6842px -25.6842px 64.2105px rgba(255, 255, 255, 0.8),
        25.6842px 6.42105px 102.737px rgba(18, 61, 101, 0.3),
        inset -12.8421px -9.63158px 128.421px rgba(255, 255, 255, 0.18);
      border-radius: 60px;
      width: 145px;
      padding: 4px 15px;
    }

    @media only screen and (max-width: 600px) {
      margin: 0px auto;
      width: 70%;

      h1 {
        font-size: 36px;
      }

      p {
        font-size: 24px;
        line-height: 26px;
      }

      .btn-error-back {
        border-radius: 50px;
        width: 130px;
        font-size: 14px;
      }
    }
  }
}

.MuiBackdrop-root {
  background: none !important;
}

.boxError {
  max-width: 400px;
  margin: 15vh auto;
  border: solid 1px lightgray;
  border-radius: 10px;
  height: 70vh;
  padding-top: 30px;

  .page-header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 40px;
  }

  .noclass {
    box-shadow: none;
    border: none;
    background: none;

    .card-header {
      box-shadow: none;
      border: none;
      background: none;
      padding: 0px 30px;
      min-height: auto;
    }

    .card-body {
      box-shadow: none;
      border: none;
      background: none;
      padding: 0px 30px;
      min-height: auto;
      margin: auto;
    }
  }
}

.phone_with_ddd.Mui-error.Mui-error {
  color: #f44336;
  font-size: 1rem;
}

.dropInput .input-group.Mui-error {
  border: solid 1px #f44336;
}

.MuiAutocomplete-popper {
  height: 100px !important;
}

.MuiTooltip-tooltip {
  background: #ffffff !important;
  border: none;
  box-shadow: 0px 0px 13px 3px rgba(181, 195, 203, 1);
  width: 115px;
}

.tooltipWhite {
  .tooltip-inner {
    background: #ffffff !important;
    border: none;
    box-shadow: 0px 0px 13px 3px rgba(181, 195, 203, 1);
    // width: 115px;
    width: 1000px;
    color: $mid-blue;
  }
}

.bs-tooltip-top .arrow::before {
  border-top-color: #fff !important;
}

.browSetting {
  text-align: left;
  max-width: 770px;
  margin: auto;
  font-size: 9.55556px;
  line-height: 1.25;

  ul {
    list-style-type: decimal;
    padding-left: 17px;

    ul {
      list-style-type: disc;
    }
  }
}

.customGmapWrapper {
  max-width: 411px;
  margin: 24px auto 0;
  padding-bottom: 20px;
}

#mandatoryPin {
  z-index: 0 !important;
  // z-index: 10000001 !important;
  width: 100% !important;
  min-height: 28px !important;
  background: $black-blue;
  z-index: 9999;
  display: flex;
  flex-direction: row;
  backdrop-filter: blur(157.781px);
  bottom: 0 !important;

  .pinStripe {
    background: transparent !important;
    width: 100% !important;
    right: 0px;
    top: 0px;
    backdrop-filter: blur(157.781px);

    p {
      z-index: 10000001 !important;
      font-family: "SF Pro Text";
      font-style: normal;
      text-align: left;
      color: #ffffff;
      margin: 8px 20px;
      font-weight: 400;
      font-size: 12.757px;
      line-height: 13px;
    }
  }
}

#title {
  color: #fff;
  background-color: #4d90fe;
  font-size: 25px;
  font-weight: 500;
  padding: 6px 12px;
}

#ribbionParent {
  // z-index: 10000001 !important;
  width: 100% !important;
  height: 65px !important;
  background: rgba(235, 245, 255, 1);
  z-index: 9999;
  display: flex;
  flex-direction: row;
  backdrop-filter: blur(157.781px);
  bottom: 0 !important;

  .mappinbtn {
    z-index: 10000001 !important;
    right: 0;
    margin-left: auto;

    button {
      position: relative;
      top: 9px;
      z-index: 10000001 !important;
      background: none #fff;
      border: 0;
      cursor: pointer;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
      width: 30px !important;
      height: 27px !important;
      border-radius: 15px;
      overflow: hidden;
      margin: 0 10px !important;

      // box-shadow: 0 0.91872px 0.91872px rgb(0 0 0 / 25%);
      img {
        z-index: 10000001 !important;
        width: 100%;
      }
    }
  }
}

.direct-bank-transfer {
  font-size: 14px;

  .dbt-explanation {
    font-style: normal;
    font-weight: normal;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: $mid-grey;
    text-align: left;
    max-width: 770px;
    margin: auto;
    margin-bottom: 20px;
    line-height: 1.15rem;
  }

  .dbt-message {
    font-style: normal;
    font-weight: normal;
    line-height: 22px;
    align-items: center;
    color: $mid-grey;
    text-align: left;
    max-width: 770px;
    margin: auto;
    margin-top: 20px;
    line-height: 1.15rem;
  }

  .dbt-details {
    text-align: left;

    .dbt-title {
      font-style: normal;
      font-weight: normal;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: $mid-grey;
      display: block;
      width: 100%;

      .dbt-divider {
        margin-left: 1.75rem;
      }

      .dbt-label {
        word-break: break-word;
        margin-left: 0.5rem;
        font-style: normal;
        font-weight: normal;
        line-height: 22px;
        display: flex;
        align-items: center;
        color: $black-blue;
      }
    }
  }
}

.transactionSlipMainWrapper {
  background: transparent;
  position: relative;
  text-align: left;
  margin-top: 30px;
  margin-bottom: 40px;
  padding-top: 26px;
  padding-bottom: 31px;
  padding-left: 18px;
  padding-right: 18px;

  &.blue-divider {
    border-left: 1px solid $blue;
    border-right: 1px solid $blue;
    // border-left: 1px solid RGB(var(--theme-custom-color));
    // border-right: 1px solid RGB(var(--theme-custom-color));

    &:before {
      background: url("../images/transaction-slips/blue/top-bar.png") no-repeat
        bottom;
    }

    &:after {
      background: url("../images/transaction-slips/blue/bottom-bar.png")
        no-repeat bottom;
    }

    .screen-capture {
      span {
        .saveText {
          color: $blue;
          // color: RGB(var(--theme-custom-color));
        }
      }
    }

    .transactionSlipDetails {
      .amountPay {
        h2 {
          color: $blue;
          // color: RGB(var(--theme-custom-color));
        }
      }

      .transaction-detail {
        &.text-black {
          h5 {
            color: $black-blue;
          }
        }

        h5 {
          color: $blue;
          // color: RGB(var(--theme-custom-color));
        }

        p {
          span {
            color: $blue;
          }
        }
      }

      p {
        span {
          // color: RGB(var(--theme-custom-color));
          color: $blue;
        }
      }
    }
  }

  &.green-divider {
    border: 1px solid $green;

    &:before {
      background: url("../images/transaction-slips/green/top-bar.png") no-repeat
        bottom;
    }

    &:after {
      background: url("../images/transaction-slips/green/bottom-bar.png")
        no-repeat bottom;
    }

    .screen-capture {
      span {
        .saveText {
          color: $green;
        }
      }
    }

    .transactionSlipDetails {
      .amountPay {
        h2 {
          color: $green;
        }
      }
    }

    .transaction-detail {
      h5 {
        color: $green;
      }

      &.text-black {
        h5 {
          color: $black-blue;
        }
      }
    }

    p {
      span {
        // color: RGB(var(--theme-custom-color));
        color: $green;
      }
    }
  }

  &:before {
    height: 8px;
    width: 100%;
    content: "";
    position: absolute;
    top: -1px;
    left: 0;
  }

  &:after {
    height: 8px;
    width: 100%;
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
  }

  .transactionSlipTopHeader {
    display: flex;

    .dbtLogo {
      height: 30px;
      background: url("../images/payment/dbt-icon.png") no-repeat bottom;
      width: 30px;
    }

    .easyPaisaLogo {
      height: 43px;
      background: url("../images/payment/easy-paisa.png") no-repeat bottom;
      width: 201px;
    }

    h5 {
      font-size: 23px;
      word-break: break-word;
      margin-left: 10px;
      line-height: 32px;
    }
  }

  .screen-capture {
    height: 43px;
    margin-left: auto;
    cursor: pointer;

    span {
      display: flex;
      flex-direction: column;

      img {
        margin: auto;
        max-width: 32px;
      }

      .saveText {
        font-family: $SF_TextRegular;
        font-style: normal;
        font-weight: bold;
        font-size: 13.9855px;
        line-height: 88.4%;
        /* or 12px */
        text-align: center;
        text-transform: uppercase;

        /* Primary / Green */
        margin-top: 7px;
        display: block;
      }
    }
  }

  .transactionSlipDetails {
    padding-top: 18.6px;
    text-align: left;

    .amountPay {
      h4 {
        font-style: normal;
        font-weight: bold;
        font-size: 13.9855px;
        line-height: 88.4%;
        /* or 12px */
        text-transform: uppercase;
        /* Secondary / Mid Grey */
        color: $mid-grey;
        font-family: $SF_TextRegular;
        padding-bottom: 9px;
        margin-bottom: 0;
      }

      h2 {
        font-style: normal;
        font-weight: 900;
        font-size: 28.8566px;
        line-height: 92.69%;
        /* identical to box height, or 27px */
        font-family: $SF_TextRegular;
        padding-bottom: 13px;
        margin-bottom: 0;
      }
    }

    .remainingTime {
      h4 {
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 16px;
        color: $mid-grey;
        font-family: $SF_TextRegular;
        padding-bottom: 6px;
        margin-bottom: 0;
      }

      h5 {
        font-family: $SF_TextRegular;
        font-style: normal;
        font-weight: bold;
        font-size: 13.9855px;
        line-height: 88.4%;
        /* or 12px */
        color: $mid-grey;
        text-transform: uppercase;
        padding-bottom: 14px;
        margin-bottom: 0;
      }
    }

    .transaction-detail {
      h4 {
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 16px;
        color: $mid-grey;
        font-family: $SF_TextRegular;
        padding-bottom: 6px;
        margin-bottom: 0;
      }

      h5 {
        font-family: $SF_TextRegular;
        font-style: normal;
        font-weight: bold;
        font-size: 13.9855px;
        line-height: 88.4%;
        /* or 12px */
        color: $green;
        text-transform: uppercase;
        padding-bottom: 17px;
        margin-bottom: 0;
      }
    }

    p {
      font-family: $SF_TextRegular;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
      /* or 114% */
      /* Secondary / Mid Grey */
      color: $mid-grey;
      margin-bottom: 0;

      span {
        .MuiButtonBase-root {
          padding-left: 0;
          padding-right: 5px;

          span > svg {
            color: #a1c9f1;
          }
        }

        font-size: 15px;
        // text-transform: uppercase;
        font-weight: 600;
        background: none;
        border: none;
        padding: 0;
        outline: 0 none;
        cursor: pointer;

        span {
          line-height: 26px;
        }
      }
    }
  }
}

.removeBackdrop {
  .ModalButtons {
    flex-direction: column;
    margin-bottom: 20px;

    .btn {
      min-width: 245px;
      margin-left: 0;

      &:last-child {
        margin-top: 10px;
      }
    }
  }
}

.processingCardRadio {
  .card {
    .card-header {
      min-height: 50px !important;

      &:after {
        content: none;
      }

      &:before {
        content: none;
      }
    }
  }
}

.fs-container {
  .row {
    margin: 0;

    .col-sm-12 {
      padding: 0;
    }
  }

  .FullScreenGmapWrapper {
    // max-width: 1411px;
    max-width: 100% !important;
    position: relative;

    .gm-bundled-control {
      margin: 4px !important;
    }

    .GmapContainerElement {
      // height: calc(100vh - 175px) !important;
      // height: calc(79vh) !important;
      height: calc(76.65vh) !important;
      position: absolute;
      width: 100%;
      overflow: hidden !important;

      .GmapMapElement {
        border-radius: 0;
      }
    }

    #fullScreenToggleBtn {
      display: none;
    }

    #ribbionParent {
      display: none;
    }

    // .mapRibbion{
    //     p {
    //         margin: 0px 7px;
    //         line-height: 45px;
    //     }
    // }

    // .mappinbtn{
    //     button {
    //         top: 8px;
    //     }
    // }
  }
}

/*
    *
    *   COUNTRY PHONE DROPDOWN
    *
*/

.country-phone-input {
  .custom-tel-flag-dropdown {
    background: transparent;

    .selected-flag {
      padding: 0;

      .flag {
        margin-left: 5px;
      }
    }
  }
}

/*
    *
    *   VOUCHER
    *
*/

.voucher_code {
  .MuiFormLabel-root.Mui-focused {
    color: RGB(var(--theme-custom-color)) !important;

    .primary {
      color: RGB(var(--theme-custom-color));
    }
  }

  &.secondary {
    .MuiFormLabel-label {
      color: $grayCol !important;
    }

    .MuiFormLabel-root {
      color: $grayCol !important;
    }

    .Mui-focused {
      color: RGB(var(--theme-custom-color)) !important;
    }

    .MuiOutlinedInput-root {
      & fieldset {
        border-color: $grayCol !important;
      }

      &:hover fieldset {
        border-color: $grayCol !important;
      }

      &.Mui-focused fieldset {
        border-color: RGB(var(--theme-custom-color)) !important;
      }
    }
  }

  &.danger {
    .MuiFormLabel-label {
      color: #f44336 !important;
    }

    .MuiFormLabel-root {
      color: #f44336 !important;
    }

    .MuiOutlinedInput-root {
      & fieldset {
        border-color: #f44336 !important;
      }

      &:hover fieldset {
        border-color: #f44336 !important;
      }

      &.Mui-focused fieldset {
        border-color: #f44336 !important;
      }
    }
  }

  &.success {
    .MuiFormLabel-root {
      color: #37d2b2 !important;
    }

    .MuiFormLabel-label {
      color: #37d2b2 !important;
    }

    p#outlined-error-helper-text-helper-text {
      color: #37d2b2 !important;
    }

    .MuiOutlinedInput-root {
      & fieldset {
        border-color: #37d2b2 !important;
      }

      &:hover fieldset {
        border-color: #37d2b2 !important;
      }

      &.Mui-focused fieldset {
        border-color: #37d2b2 !important;
      }
    }
  }

  .vouchAdornment {
    cursor: pointer;
    box-shadow: none;
    font-family: SF Pro Text;
    font-style: normal;
    font-weight: bold;
    font-size: 13.9855px;
    line-height: 88.4%;
    text-transform: uppercase;
    // color: $grayCol;
    color: RGB(var(--theme-custom-color));
    margin-left: 0.5rem;

    &.rtl-position {
      position: absolute;
    }

    &.primary {
      color: RGB(var(--theme-custom-color));
    }

    &.danger {
      color: #f44336;
    }

    &.success {
      color: #37d2b2;
    }
  }
}

/*
    *
    *   RECEIPT
    *
*/

.receiptSection {
  .receiptDiscounts {
    .receiptSub {
      margin-bottom: 0;

      .right {
        font-size: 14px;
      }

      .left {
        width: 60%;

        div {
          p {
            padding-left: 6px;
            padding-right: 6px;
            width: 69%;
            padding: 6px 6px;
            height: auto !important;
            margin-bottom: 0;

            span {
              font-size: 11.29px;
              display: inline-block;
              width: 78%;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              line-height: initial;
              text-transform: uppercase;

              &.discount-tag {
                width: 13px !important;
                height: 13px !important;
                margin: 0 5px 0 0 !important;
              }

              &.close-discount {
                display: flex;
                width: auto;

                svg {
                  font-size: 12px !important;
                }
              }
            }
          }
        }
      }
    }

    .receiptInfo {
      margin-bottom: 0;

      .receiptSub {
        .right {
          padding-top: 4px;
        }
      }
    }
  }
}

.receiptFirstBox {
  .bigReceiptHeadingBox {
    padding-top: 17px;

    .left {
      max-width: 68%;
      font-size: 16px;
      line-height: 88.4%;
    }

    .right {
      font-size: 16px;
      line-height: 88.4%;
    }

    &:first-child {
      padding-bottom: 10px;
      padding-top: 0;
    }
  }

  .receiptSubItems {
    margin-left: 0 !important;
  }
}

.receiptDiscountBox {
  margin-bottom: 10px !important;

  .bigReceiptHeadingBox {
    padding-top: 17px;

    .left {
      font-size: 16px;
      line-height: 88.4%;
      color: $black-blue !important;
      width: 50%;
    }

    .right {
      font-size: 16px;
      line-height: 88.4%;
      color: $black-blue !important;
      width: 50%;
    }
  }
}

/*
    *
    *   SPINNER LOADING
    *
*/

.loading {
  position: fixed;
  z-index: 9999999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
}

.spinner {
  position: absolute;
  /* left: 50%;
      top: 50%; */
  height: 50px;
  width: 50px;
  margin: 0px auto;
  -webkit-animation: rotation 0.6s infinite linear;
  -moz-animation: rotation 0.6s infinite linear;
  -o-animation: rotation 0.6s infinite linear;
  animation: rotation 0.6s infinite linear;
  border-left: 6px solid var(--darker-lighten-theme-custom-color);
  border-right: 6px solid var(--darker-lighten-theme-custom-color);
  border-bottom: 6px solid var(--darker-lighten-theme-custom-color);
  border-top: 6px solid RGB(var(--theme-custom-color));
  border-radius: 100%;
}

/*
    *
    *   PAYMENT METHOD
    *
*/

.bankAccTextField {
  margin-bottom: 0px !important;
}

/*
    *
    *   CREDIT CARD
    *
*/
.cvvCollectForm {
  display: flex;
  flex-wrap: wrap;
  .cvv {
    padding-left: 0px;
    padding-right: 0px;
    padding-right: 2rem !important;
  }

  label {
    &.error {
      border: 2px solid #fb0b09 !important;
      margin-bottom: 0px !important;
    }
  }

  .error-label {
    .field {
      border: 1px solid #fb0b09 !important;
      margin-bottom: 1px !important;
    }
  }
}
.creditCardCollectForm {
  #card-name {
    direction: ltr;
  }
  .twoField {
    // margin: 0 -19px !important;
    margin-bottom: 0px !important;

    .col-6 {
      // flex: 0 0 46.8%;
      // max-width: 46.8%;
      padding: 0px;
      &.expiry {
        padding-left: 0.70rem;
      }
      &.cvv {
        padding-right: 0.70rem;
      }
    }
  }

  iframe {
    width: 100%;
    height: 100%;
  }

  label {
    flex: 0 0 93%;
    min-width: 93%;
    // flex: 0 0 100%;
    // min-width: 100%;
    position: relative;
    color: #8898aa;
    font-weight: 300;
    height: 3.5rem;
    line-height: 40px;
    display: flex;
    flex-direction: row;
    // margin-bottom: 25px;

    &.error {
      margin-bottom: 0px !important;
    }
  }

  .group {
    margin-top: 0px;

    p {
      margin-bottom: 20px;
      margin-top: 5px;
      color: #f44336;
      text-align: left;
      font-size: 1rem;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      line-height: 1.1876em;
      letter-spacing: 0.00938em;
      font-style: italic;
    }
  }

  .checkBoxCard {
    margin: 0px;

    label {
      margin-bottom: 0px !important;
    }
  }

  .group label:not(:last-child) {
    border-bottom: 1px solid #f0f5fa;
  }
  .formFields {
    label {
      border-radius: 10px;
      margin-bottom: 0rem;
      box-sizing: border-box;
      box-shadow: 0px 2px 5px rgb(0 0 0 / 8%), 0px 1px 1px rgb(0 0 0 / 4%);
      &.nameFieldLabel{
        box-shadow: none !important;
      }
      &.error-label {
        border: 1px solid #fb0b09;
        border-radius: 8px;
        margin-bottom: 0px;
      }
    }
    .nameField {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 9px 12px;
      height: 52px;
      left: 0px;
      right: 24.49px;
      background: #FFFFFF;
      box-sizing: border-box;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.04);
      border-radius: 8px;
    }
    .cardNumberField{
      // display: flex;
      // flex-direction: column;
      // justify-content: center;
      // align-items: flex-start;
      // padding: 0px 0px;
      width: 100%;
      // background: #FFFFFF;
      // border: 1px solid rgba(60, 66, 87, 0.12);
      // box-sizing: border-box;
      box-shadow: 0px 2px 5px rgb(0 0 0 / 8%), 0px 1px 1px rgb(0 0 0 / 4%);
      // border-radius: 8px 8px 0px 0px;
      .card-icon {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        height: 19px;
        width: 30px;
        background-color: transparent;
        z-index: 1;
        left: unset !important;
      }
    }
    .field {
      background: #FFFFFF;
      box-sizing: border-box;
      border-radius: 0px 0px 8px 0px;
      flex: none;
      order: 1;
      flex-grow: 0;
      margin: 0px 0px;
      font-weight: 300;
      outline: none;
      cursor: text;
      width: 100%;
      border-radius: 4px;
      direction: ltr;

      &::-webkit-input-placeholder {
        color: #cfd7e0;
        margin-left: 20px;
        transform: translate(14px, 20px) scale(1);
      }
    
      &::-moz-placeholder {
        color: #cfd7e0;
        margin-left: 20px;
        transform: translate(14px, 20px) scale(1);
      }
    }
    .error{
      color: #fb0b09;
      text-align: left;
      font-family: "SF Pro Text" !important;
      font-style: normal;
      align-items: center;
      font-style: normal;
      font-size: 13px !important;
      display: flex;
      margin-bottom: 0.5em;
      letter-spacing: 0em !important;
      word-spacing: 0em !important;
    }
  }
}
/*
    *
    *   QISST PAY
    *
*/

.qisstPayIframe {
  max-width: 650px !important;
  height: 470px !important;
  width: 104%;

  .stepform2 form.slideform-form .slideform-wrapper {
    transition: all 0.5s ease;
    width: 300px !important;
    margin-top: 30px !important;
  }
}

/*
    *
    *   CYBERSOURCE
    *
*/


/*
    *
    *   FULL SCREEN MAP
    *
*/
.FullScreenMinimize{
    top: 190px;
    z-index: 101;
    position: fixed;
    right: 3px;
    .fullscreen-control {
    background: none rgb(255, 255, 255);
    cursor: pointer;
    user-select: none;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    overflow: hidden;
    border-radius: 10px;
     }
}
.FullScreenDialog{
    bottom: 20%;
    z-index: 101;
    position: fixed;
    left: 5%;
    right: unset !important;
    width: 90%;
    .overflow-scroll{
      max-height: 250px;
      overflow: scroll;
    }
    .dialog {
      margin:auto;
      border-radius: 10px;
      left: 0px;
      bottom: 0;
      position: relative;
      width: 100%;
      padding: 5px 10px !important;
      -moz-box-sizing: border-box;
      outline: none;
      background: $white;
      max-width: 370px;
      text-align: center;
      .title{
        font-size: 1.1rem;
        font-weight: bold;
      }
      .btn-ref{
        margin-top: 8px;
        font-weight: 600;
        font-size: 1rem;
        color: RGB(var(--theme-custom-color));
        @media (hover: hover) {
          &:hover {
            font-size: 1.05rem;
            cursor: pointer;
            color: var(--darker-lighten-theme-custom-color);
          }
        }
      }
    }
}

.FullScreenLocationBtnWrapper {
  z-index: 101;
  position: fixed;
  left: 5%;
  bottom: 5%;
  right: unset !important;
  width: 90%;

  .confirm-location {
    &.disabled {
      button {
        pointer-events: none !important;
        background: #8898aa !important;
        // background: var(--darker-lighten-theme-custom-color);
        color: $white !important;
      }
    }

    button {
      border-radius: 6px;
      left: 0px;
      bottom: 0;
      position: relative;
      width: 100%;
      padding: 5px 10px !important;
      -moz-box-sizing: border-box;
      outline: none;
      background: RGB(var(--theme-custom-color));
      font-size: 18px;
      border: 0;
      max-width: 370px;

      &:hover {
        outline: 0 none;
        border: 0;
        background: var(--darker-lighten-theme-custom-color);
        color: $white;
      }

      &:focus {
        background: var(--darker-lighten-theme-custom-color);
        outline: 0 none;
        border: 0;
      }
    }
  }
}

.FullScreenAddressWrapper {
  // z-index: 1000000000001;
  z-index: 101;
  position: absolute;
  left: 0px;
  top: 0px;
  right: unset !important;

  .address-pin-container {
    margin: 10px 10px 0 0;
    border-radius: 6px;
    left: 10px;
    position: relative;
    width: 300px;
    padding: 5px 10px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    outline: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    font-family: Roboto;

    &.mobile-screen {
      width: 100% !important;
    }

    .map-box {
      width: 100%;
      padding: 0;
      height: auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      position: relative;
      background: #fff;
      border-radius: 8px;
      box-sizing: border-box;
      border-bottom: 1px solid transparent;
      transition-property: background, box-shadow;
      transition-duration: 0.3s;

      form {
        margin: 0;
        height: auto;
        display: flex;
        width: 80%;
      }

      .MuiFormControl-root {
        display: flex;
      }

      #map-address {
        border: 0;
        padding: 0 8px;
        min-height: 40px;
        color: $darkGrayCol;
      }

      fieldset {
        padding-left: 0px;
        border: 0;
        padding: 0;
        height: auto;
      }

      .formRightSide {
        display: flex;
        width: 20%;
        justify-content: flex-end;

        button {
          border: 0;
          background: none;
          // margin: 0 12px 0 0;
          margin: 0 9px 0 0;
          position: relative;
          padding: 0;

          &:active {
            outline: 0 0 0;
            box-shadow: 0 0 0;
          }

          &:focus {
            outline: 0 0 0;
            box-shadow: 0 0 0;
          }

          svg {
            color: $grayCol;
          }

          img {
            // width: 20px;
            // height:20px;
          }

          &:last-child {
            padding-left: 7px;
            margin: 0;
            display: flex;
            align-items: center;
            span{
              padding-top: 4px;
            }
            &:before {
              border-left: 1px solid #ccc;
              content: "";
              width: 2px;
              height: 24px;
              position: absolute;
              left: 0;
            }
          }

          &.mapDirection {
            cursor: pointer;

            svg {
              fill: RGB(var(--theme-custom-color));
              width: 24px;
              height: 24px;

              path {
                fill: #fff;
              }
            }
          }
        }
      }
    }
  }
}

/*
    *
    *   SHIPMENT METHOD
    *
*/

.shipmentMethod {
  display: flex;
  padding: 0 !important;
  .accordion {
    width: 100%;
  }
  .activeShow {
    .shipmentMethodHeader {
      background: var(--lighten-theme-custom-color);
      &:after {
        content: "";
        height: 24px;
        width: 24px;
        display: block;
        background: RGB(var(--theme-custom-color));
        border-radius: 50%;
        position: absolute;
        right: -12px;
        top: -12px;
      }

      &:before {
        content: "";
        position: absolute;
        height: 7px;
        width: 15px;
        top: 2px;
        right: -7px;
        margin: -7px 0 0 -6px;
        border: 1px solid #fff;
        border-width: 0 0 1px 1px;
        background: none;
        border-radius: 0 !important;
        transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        z-index: 1;
      }
    }
  }

  .shipmentHeaderActions{
    line-height: 22px;
    // padding: 5px 10px;
    font-size: 20px;
    min-height: auto;
    // border-radius: 4px;
    justify-content: center;
    border: none;
    position: relative;
    cursor: pointer;
    background: none;
    -webkit-align-items: center;
    align-items: center;
    margin-bottom: 0;
    color: $black-blue;
    padding: 1px 6px;
    align-items: flex-start;
    display: -webkit-flex;
    display: flex;
    border-top: solid 1px $pink !important;

    .card-payment-protection {
      border: none !important;
      // border-top: 1px solid rgba(0, 40, 100, 0.12) !important;
      // max-height: unset !important;
      min-height: 35px !important;
      border-radius: 0 !important;
      width: 100%;
      padding: 0px 15px !important;
      font-size: 13.7224px !important;
      &:before {
        display: none !important;
      }
      &:after {
        display: none !important;
      }
      .payment-protection {
        display: flex;
        align-items: flex-end;
        padding: 11px 0;
        .iconImage {
          width: 15px;
          text-align: center;
          margin: auto;
          margin-right: 10px;
          margin-top: -2px;
          img {
            max-width: 45px;
            max-height: 43px;
            object-fit: cover;
          }
        }
        .protection {
          display: flex;
          align-items: center;
          font-style: normal;
          font-weight: normal;
          font-size: 13.7224px;
          line-height: 18px;
          text-align: center;
          letter-spacing: -0.02em;
          color: #192631;
        }
      }
    }
  }
  .shipmentMethodHeader {
    line-height: 22px;
    // padding: 5px 10px;
    font-size: 20px;
    min-height: auto;
    border-radius: 4px;
    justify-content: center;
    border: none;
    position: relative;
    cursor: pointer;
    background: none;
    -webkit-align-items: center;
    align-items: center;
    margin-bottom: 0;
    color: $black-blue;
    padding: 1px 6px;
    // transform: translate(0%, 5%);
    .shipmentHeaderBody {
      align-items: flex-start;
      display: -webkit-flex;
      display: flex;
      .head {
        max-height: 50px;
        min-height: 50px;
        display: inline-block;
        vertical-align: middle;
        line-height: 2.25rem;
      }
      .icon {
        display: flex;
        justify-content: flex-start; /* align horizontal */
        align-items: center; /* align vertical */
        width: 75px;
        margin-right: 17px;
        margin-left: 12px;
      }
      .information {
        width: 190px;
        // text-align: left;
        padding: 5px;
        border-left: solid 1px #b3b3b3;
        display: flex;
        justify-content: flex-start;
        // justify-content: center; /* align horizontal */
        align-items: center; /* align vertical */
        &.active {
          border-left: 1px solid rgb(var(--theme-custom-color));
        }
        h5 {
          margin-left: 15px;
          margin-bottom: 0;
        }
        .overflowText {
          // display: -webkit-box;
          display: inline-block;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
  }
  .shipmentMethodBody {
    .description {
      display: flex;
      padding: 0 !important;

      .shipmentPrice {
        width: 111px;
        // width: 81px;
        display: flex;
        align-items: center;
        // width: 35%;
        font-style: normal;
        font-weight: 800;
        font-size: 12px;
        /* identical to box height, or 18px */
        // text-align: left;
        /* identical to box height, or 18px */
        text-align: center;
        /* Secondary / Blackish Blue */
        border-right: 1px solid RGB(var(--theme-custom-color));
        color: $black-blue;
        padding: 20px 13px;
      }

      .shipmentDesc {
        width: 65%;
        padding: 20px 13px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        /* identical to box height, or 18px */
        text-align: center;
        /* Secondary / Blackish Blue */
        text-align: left;
        min-width: 210px;
        max-width: 210px;
      }
    }
  }
}

/*
    *
    *   PAYMENT METHOD
    *
*/

.paymentMethod {
  padding-bottom: 130px !important;

  .paymentMethodHeader {
    .icon {
      width: 26px;
    }
    p {
      font-size: 14px;
    }
    span {
      font-weight: 600;
      font-size: 10px;
      text-transform: uppercase;
      letter-spacing: 0.3px;
      color: $mid-grey;
      line-height: 1px;
    }
    .trashIcon {
      justify-content: center;
      align-items: center;
      .icomoon-icon-trash {
        font-size: 20px !important;
        color: RGB(var(--theme-custom-color)) !important;
      }
    }
    .paymentMethodHeaderBody {
      text-align: right;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .information {
        width: 190px;
        text-align: left;
        margin-left: 10px;

        h5 {
          margin-bottom: 0;
        }

        h6 {
          font-size: 13px;
          // margin-top: 6px ;
          margin-left: 0px;
          margin-bottom: 0;
          color: #bdbdbd;
        }
        .overflowText {
          margin-top: 5px;
          margin-bottom: 0;
        }
      }
      .codImage {
        width: 42px;
        text-align: center;
        margin: auto;

        img {
          max-width: 45px;
          max-height: 43px;
          object-fit: cover;
        }
      }
    }
  }
  .easyPaisa {
    .easyPaisaSuggestionList {
      color: #91989cd6;
      // font-size: 12px;
      font-weight: 600;
      text-align: left;
      margin-bottom: 3px;
      text-transform: uppercase;
      line-height: 26px;
      word-spacing: 2px;
      font-family: SF Pro Text;
      font-style: normal;
      font-weight: bold;
      font-size: 8.55556px;
      line-height: 88.4%;
      color: #91989C;
    }

    .numberList {
      line-height: 24px;
      color: RGB(var(--theme-custom-color));
      text-align: left;
      margin-bottom: 2px;

      span {
        line-height: 24px;
        font-size: 15px;
        font-weight: 600;
        text-align: left;
        margin-bottom: 3px;
        cursor: pointer;
        text-decoration: none;
      }

      span:hover {
        text-decoration: underline;
      }
    }
  }
  .easypaisaMA {
    .dropInput {
      .input-group {
        border-radius: 0.25rem;
        border: solid 1px $darkGrayCol;

        .ep-dropdown {
          background: transparent;
          width: 7.9rem;
          border: none;
          margin: 0;
          padding: 9px 11px;
          display: flex;
          align-items: center;

          .flagImg {
            flex: auto !important;
            text-align: left;
            max-width: 44px;
          }

          .CCode {
            font-size: 18px !important;
            flex: auto !important;
            text-align: left;
            justify-content: flex-start;
            color: $grayCol;
            margin-left: 6px;
          }
        }

        .input-group-prepend {
          position: relative;
          margin-right: 0;

          &::before {
            content: " ";
            right: 0px;
            height: 24px;
            width: 1px;
            position: absolute;
            display: block;
            background: $grayCol;
            top: 0;
            bottom: 0;
            margin: auto;
          }

          .dropdown-toggle {
            &::after {
              display: inline-block;
              margin-left: 0.255em;
              vertical-align: 0.255em;
              content: "";
              border-top: 0.6em solid;
              border-right: 0.3em solid transparent;
              border-bottom: 0;
              border-left: 0.3em solid transparent;
              position: relative;
              top: 5px;
            }
          }
        }

        .Mui-error {
          border: solid 1px #f44336;
        }

        .error {
          border: solid 1px #f44336;
        }

        .btn {
          font-size: 20px;
          color: $grayCol;
        }

        .form-control {
          &:focus {
            box-shadow: none;
            outline: none;
          }
        }

        .dropdown-menu {
          min-width: auto;
          padding: 0;
          margin: 0;
          font-size: 20px;
          color: $grayCol;

          .dropdown-item {
            padding: 0.25rem 1rem;
            border-bottom: solid 1px #e6e6e6;
          }
        }
      }

      .error {
        border: solid 1px #f44336;
      }
    }

    .checkBoxCard {
      margin: 0px 0 0 0 !important;
    }

    .epCheckboxCard {
      margin-top: -1rem !important;
    }
  }
}

.ccGatewayCheckboxCard {
  .saveDetailsFooter {
    .icon  {
      color: #8792a2;
      &.active {
        color: rgb(var(--theme-custom-color)) !important;
      }
    }
    .footContent {
      font-family: "SF Pro Display";
      font-style: normal;
      align-items: center;
      font-style: normal;
      font-size: 13px !important;
      display: flex;
      letter-spacing: 0em !important;
      word-spacing: 0em !important;
      color: #91989C;
    }
  }
}

.MuiCheckbox-colorPrimary {
  color: rgba(0, 0, 0, 0.54) !important;
  &.Mui-checked{
    color: rgb(var(--theme-custom-color))!important;
  }
}
/*
    *
    *   CART WRAPPER
    *
*/

.cartIcon {
  display: flex;
  margin: 16px 7px;

  .nav-link {
    position: relative;
    padding: 0;
    i {
      font-size: 1.7rem;
      color: rgb(var(--theme-custom-color))!important;
    }
    img {
    }

    .cartCounter {
      background: $white;
      box-shadow: 0px 1.58333px 1.58333px rgb(50 50 71 / 6%),
        0px 1.58333px 3.16667px rgb(50 50 71 / 6%);
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -8px;
      border-radius: 100%;
      right: -6px;
      padding: 0 2px;

      span {
        background: RGB(var(--theme-custom-color));
        font-style: normal;
        font-weight: bold;
        font-size: 9px;
        line-height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border-radius: 100%;
        color: #ffffff;
      }
    }
  }
}

.cartMenu {
  // height: 80px;
  background: #ebf5ff;
  display: flex;
  justify-content: center;
  align-items: center;

  .accordion {
    width: 90%;

    .card {
      background-color: transparent;

      .card-header {
        border: 1px solid #fff;
        box-shadow: 0 0 0 !important;
        padding: 7px 13px;
        flex-direction: column;
        border-bottom: 0;
        min-height: unset;
        cursor: pointer;
        min-height: 54px;
      }
    }
  }
}

.cartSlideWrapper {
  position: relative;
  left: 0;
  width: 100%;
  z-index: 1;
  top: 0;
  background: var(--lighten-theme-custom-color);

  .accordion {
    width: 100%;
    border: 0;

    .card {
      border: 0;
      margin: 0;

      .card-body {
        padding: 10px 0px 0;
        // padding: 25px 0px 0;
        margin: 0 auto;
      }
    }

    .closeSlideCart {
      position: absolute;
      bottom: 8px;
      width: 60px;
      left: 0;
      right: 0;
      margin: 0 auto -8px;

      button {
        background: RGB(var(--theme-custom-color));
        border: 0;
        border-radius: 9.6577px;
        width: 60px;
        height: 19px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: 0 auto -8px;

        i {
          color: $white;
          transform: rotate(180deg);
          font-size: 19px;
          font-weight: 600;
        }
      }
    }
  }

  .cartSlide {
    padding: 0px 0;

    .insideCartSlideWrap {
      margin: auto;
      // height: 260px;
      height: 210px;
      overflow: auto;
      overflow-x: hidden;
      max-width: 1140px;
      padding: 0 15px 25px;

      &::-webkit-scrollbar {
        width: 3px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 12px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #b9c0c4;
        width: 1px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #b9c0c4;
      }

      .insideWrapper {
        max-width: 411px;
        margin: 0px 1.25rem;
      }

      .cartTopButtons {
        display: flex;

        button {
          display: flex;

          &.generalCartSlideBtn {
            border: 1px solid RGB(var(--theme-custom-color));
            background: transparent;
            border: 1.1052px solid RGB(var(--theme-custom-color));
            box-sizing: border-box;
            border-radius: 3.69757px;
            width: 48%;
            padding: 9px 9px;
            display: flex;
            align-items: center;
            flex-direction: row;
            margin: 0 4% 0 0;
            justify-content: center;

            &:hover {
              outline: 0 none;
              box-shadow: 0 0 0;
            }

            &:focus {
              outline: 0 none;
              box-shadow: 0 0 0;
            }

            &:last-child {
              margin-right: 0;
            }

            span {
              font-style: normal;
              font-weight: normal;
              font-size: 14.7222px;
              line-height: 20px;
              /* identical to box height, or 133% */
              display: flex;
              align-items: center;
              text-align: center;
              /* Primary / Blue */
              color: RGB(var(--theme-custom-color));
              padding-left: 8px;
            }

            .cartBtnIcon {
              color: RGB(var(--theme-custom-color));
              font-size: 19px;
            }
          }
        }
      }

      .cartProductsWrap {
        display: flex;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 26px;

        .cartPrdLeft {
          width: 70%;
          padding-right: 18px;

          .storeInfo{
            align-items: center;
            .store-name {
              width: 100% !important;
              font-weight: 600;
              font-size: 12px;
              margin-left: 6px;
              line-height: 20px;
              -webkit-line-clamp: 1;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
            }
            .store-icon {
              max-width: 23px;
              max-height: 23px;
              width: 100% !important;
              margin: 0 auto !important;
              img{
                border-radius: 50%;
                padding: 2px;
                border: 1px solid;
                border: solid 1px RGB(var(--theme-custom-color));
              }
            }
          }

          .cartProdName {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            // margin-bottom: 12px;
            font-style: normal;
            font-weight: 600;
            font-size: 14.7982px;
            line-height: 20px;
            color: $black-blue;
          }

          .cartVariations {
            display: -webkit-box;
            // -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            // overflow: hidden;
            // margin-bottom: 7px;
            margin-bottom: 12px;
            font-style: normal;
            font-weight: 600;
            font-size: 13.7982px;
            line-height: 20px;
            color: RGB(var(--theme-custom-color));
          }
          .cartAddNotes {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            margin-bottom: 7px;
            font-style: normal;
            font-weight: 600;
            font-size: 11.7982px;
            line-height: 20px;
            color: RGB(var(--theme-custom-color));
          }

          .cartInfoBottom {
            color: RGB(var(--theme-custom-color));

            .qty {
              font-weight: 600;
              font-size: 15px;
              line-height: 20px;
              margin: 0.25rem 0px 0px 0px;
              // margin: 0.25rem 0px;
            }

            .price {
              margin-top: 0.2rem;
              text-align: left;
            }

            .slideProdPrice {
              span {
                font-style: normal;
                font-weight: bold;
                font-size: 21.1041px;
                line-height: 88.4%;
                color: RGB(var(--theme-custom-color));

                &:first-child {
                  margin-right: 5px;
                }
              }
              &.strikeText {
                text-decoration: line-through;
                color: rgb(var(--theme-custom-color));
              }
              &.suggestPrice {
                span {
                  color: rgb(var(--lighten-theme-custom-color));
                  font-size: 15.1041px;
                }
              }
            }
          }

          .cartActionsWrap {
            display: flex;
            width: 70%;

            .trashBtn {
              border: 1.09877px solid #b9c0c4;
              border-radius: 2.74211px;
              padding: 0;
              width: 35%;
              height: 44px;
              background: transparent;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 5%;
              cursor: pointer;

              i {
                color: $black-blue;
                font-size: 19px;
                font-weight: 600;
              }

              &:hover {
                outline: 0 none;
                box-shadow: 0 0 0;
              }

              &:focus {
                outline: 0 none;
                box-shadow: 0 0 0;
              }
            }

            .prodQtyWrap {
              display: flex;
              width: 100%;

              button {
                border: 1.09877px solid #b9c0c4;
                border-radius: 2.74211px 0px 0px 2.74211px;
                padding: 0;
                width: 35%;
                height: 44px;
                background: transparent;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 5%;
                cursor: pointer;

                i {
                  color: $grayCol;
                  font-size: 4px;
                  font-weight: 600;
                }

                &:hover {
                  outline: 0 none;
                  box-shadow: 0 0 0;
                  border-color: RGB(var(--theme-custom-color));

                  i {
                    color: RGB(var(--theme-custom-color));
                  }
                }

                &:focus {
                  outline: 0 none;
                  box-shadow: 0 0 0;
                  border-color: RGB(var(--theme-custom-color));

                  i {
                    color: RGB(var(--theme-custom-color));
                  }
                }

                &:last-child {
                  border-radius: 0px 2.74211px 2.74211px 0px;
                  margin-right: 0;

                  i {
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }

        .cartPrdRight {
          width: 30%;

          .cartProdImgWrap {
            position: relative;
            background: #ffffff;
            /* Primary / Blue */
            border: 1.884px solid RGB(var(--theme-custom-color));
            box-sizing: border-box;
            border-radius: 5.65201px;
            padding: 10px;
            align-items: center;
            display: flex;
            justify-content: center;

            img {
            }
          }
        }
      }
    }
    .cartSlideHeader {
      box-shadow: 0px -2px 15px rgba(0, 64, 255, 0.15);
      padding: 10px 0;
      text-align: center;
      display: flex;

      .cartSlideHeaderContainer {
        max-width: 822px;
        margin: auto;
        width: 100%;
        align-items: center;
      }

      span {
        font-style: inherit;
        font-family: "SF Pro Display";
        font-weight: 600;
        font-size: 16.4758px;
        line-height: 88.4%;
        width: 100%;
        color: #192631;
      }
    }
    .cartSlideTotal {
      box-shadow: 0px -2px 15px rgba(0, 64, 255, 0.15);
      padding: 23px;
      text-align: center;
      display: flex;

      .cartSlideTotalContainer {
        max-width: 411px;
        margin: auto;
        width: 100%;
        display: flex;
        align-items: center;
      }

      span {
        font-style: normal;
        font-weight: normal;
        font-size: 19.4758px;
        line-height: 88.4%;
        width: 50%;
        justify-content: flex-start;
        display: flex;
        flex-wrap: wrap;
        color: RGB(var(--theme-custom-color));

        &.cartSlideTotalAmount {
          justify-content: flex-end;
          width: 50%;
          display: flex;
          // flex-wrap: wrap;
          flex-wrap: nowrap;
          font-size: 24.5188px;

          span {
            width: auto;
            font-size: 24.5188px;
            padding-right: 5px;
          }
        }
      }
    }
  }

  .nestedAccordion {
    background: transparent;
    padding: 0;

    .billingDetailsSlide {
      display: flex;
      align-items: center;
      cursor: pointer;

      span {
        font-style: normal;
        font-weight: normal;
        font-size: 14.7222px;
        line-height: 20px;
        padding-left: 10px;
        color: RGB(var(--theme-custom-color));
      }

      i {
        &.new-icon-billing {
          color: RGB(var(--theme-custom-color));
          font-size: 24px;
        }

        &.icomoon-navigate-bottom {
          margin-left: auto;
          color: RGB(var(--theme-custom-color));
          font-size: 18px;
          font-weight: 600;
        }
      }
    }

    .billingDetailsWrap {
      .receiptSection {
        background: transparent;
        // margin: 25px 0 0 0;
        margin: 25px 15px 0px 15px;
        padding: 0;

        &:before {
          background: none;
        }

        &:after {
          background: none;
        }

        .subTotalDetails {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 88.4%;
          color: $navyblue;

          .receiptSubItems {
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;
            font-weight: 300;
            font-size: 16px;
            line-height: 20px;
            color: $mid-grey;
            margin-left: 0.5rem;
      
            .left {
              font-size: 14px;
            }
      
            .right {
              font-size: 14px;
            }
          }
          
          .bigReceiptHeadingBox {
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;

            .left {
              max-width: 68%;
            }
          }

          .insidesubTotalBox {
            font-style: normal;
            font-weight: 300;
            font-size: 14.5461px;
            line-height: 18px;
            color: $mid-grey;
            display: flex;
            justify-content: space-between;
            margin-bottom: 8.5px;
          }
        }

        .shippingFee {
          font-style: normal;
          font-weight: normal;
          font-size: 18.1827px;
          line-height: 88.4%;
          color: $navyblue;
          margin: 16px 0 16px 0;

          .shippingSubInfo {
            display: flex;
            justify-content: space-between;
            margin-bottom: 15px;
          }
        }

        .receiptDiscounts {
          border-top: 0.454567px solid #b9c0c4;

          .receiptInfo {
            padding: 0;

            .left {
              div {
                position: relative;
                display: flex;
                align-items: center;

                p {
                  height: 100%;
                  background: RGB(var(--theme-custom-color));
                  border-radius: 2.25843px;
                  color: var(--lighten-theme-custom-color);
                  display: flex;
                  width: 60%;
                  font-size: 10px;
                  align-items: center;
                  justify-content: center;
                  margin-right: 11px;
                  padding: 2px;
                  margin-bottom: 0;

                  .discount-tag {
                    width: 10px;
                    height: 8px;
                    margin-left: 0;
                  }

                  .close-discount {
                    svg {
                      transform: none;
                      font-size: 12px;
                    }
                  }
                }
              }
            }

            .right {
              font-style: normal;
              font-weight: 300;
              font-size: 14.5461px;
              line-height: 18px;
              text-align: right;
              color: $mid-grey;
            }

            &.receiptDiscountBox {
              margin-bottom: 0;
              margin-top: 16px;

              .bigReceiptHeadingBox {
                font-style: normal;
                font-weight: normal;
                font-size: 18.1827px;
                line-height: 88.4%;
                color: $navyblue;
                margin-bottom: 0;

                .left {
                }

                .right {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 18.1827px;
                  line-height: 88.4%;
                  color: $navyblue;
                }
              }
            }
          }
        }
      }
    }
  }
}

.web-sidebar {
  top: 65px !important;
  padding-left: 11px;
  max-width: 411px;
  width: auto;
  box-shadow: rgb(0 0 0 / 15%) 0px 0px 0px !important;
  min-width: 411px;

  .show {
    width: 100%;
  }

  .cartSlideWrapper {
    height: 100% !important;
    align-items: unset;
    padding-top: 90px;

    .card-body {
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 30px;
      padding-left: 30px;
    }

    .cartSlide {
      .insideCartSlideWrap {
        padding: 0 15px 25px;
        height: 385px;

        .cartTopButtons {
          button {
            &.generalCartSlideBtn {
              cursor: pointer;

              span {
                font-size: 13px;
              }
            }
          }
        }

        .cartProductsWrap {
          .cartPrdLeft {
            .cartActionsWrap {
              width: 83%;
            }
          }
        }
      }

      .cartSlideTotal {
        box-shadow: 0px 0px 0px rgb(0 64 255 / 15%);
      }
    }

    .closeSlideCart {
      position: absolute;
      bottom: 0px;
      width: 20px;
      left: 0;
      margin: 0 0 0 -8px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      background: #f8f9f2;

      button {
        background: RGB(var(--theme-custom-color));
        border: 0;
        border-radius: 9.6577px;
        width: 20px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin: 0 auto 0px;

        i {
          color: $white;
          transform: rotate(270deg);
          font-size: 19px;
          font-weight: 600;
        }
      }
    }
  }
}

.cartDropdown {
  float: right;

  .dropdown-menu {
    float: right;
    border-radius: 18px;
    background-color: #ffffff;
    box-shadow: 0 0 20px 0 rgb(0 0 0 / 10%);
    border: 0;
    min-width: 375px;
    // min-width: 225px;
    padding: 0;
    margin: 5px 0 0 -15px !important;
    // border: 1.4px solid RGB(var(--theme-custom-color));
    border-radius: 5.5px;
    right: 0 !important;
    left: auto !important;
    top: 62px !important;
    transform: none !important;
    // position: absolute;
    // z-index: 1000;

    .dropdown-list {
      margin: 0;
      padding: 0;

      .dropdown-item {
        height: 40px;
        line-height: 40px;
        padding: 0;
        color: $grayBg;
        font-weight: bold;
        border-bottom: solid 0.5px $grayBg;

        .icon {
          margin: 0 15px;
          display: inline-block;
          float: left;
          font-size: 22px;
          text-align: center;
          width: 24px;
          color: $grayBg !important;
        }

        .item-text {
          width: 150px;
          display: inline-block;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          text-transform: uppercase;
        }

        &:last-child {
          border-bottom: none;
        }

        &.active {
          color: RGB(var(--theme-custom-color));
          background: none;

          .icon {
            color: RGB(var(--theme-custom-color)) !important;
          }
        }

        &:hover {
          background: none;
        }
      }
    }
  }
}

/*
    *
    *   DIALOG CSS
    *
*/

.dialogPopup {
  height: 370px;
  right: 0 !important;
  bottom: 0 !important;
  top: 0 !important;
  left: 0 !important;
  width: 480px;
  margin: auto !important;

  .MuiBackdrop-root {
    background-color: var(--lighten-theme-custom-color) !important;
  }
  .MuiDialog-scrollPaper {
    align-items: center !important;
  }

  .MuiDialog-paperScrollPaper {
    // box-shadow: 0px 18.8925px 15.114px rgba(0, 64, 255, 0.05), 0px 23.9305px 47.8611px rgba(0, 0, 0, 0.1);
  }

  .MuiDialog-paper {
    overflow: hidden;
    background: transparent !important;
    border: 0;
    box-shadow: 0 0 0;

    .dialogPopupInv {
      background: white;
      overflow-y: auto;
      text-align: left;
      @include border-radius(10px);

      &::-webkit-scrollbar {
        width: 6px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #fff;
        border-radius: 6px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #e6e6e6;
        width: 1px;
        border-radius: 6px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #e6e6e6;
        border-radius: 6px;
      }

      .MuiDialogTitle-root {
        padding: 22px 24px 0px !important;
      }

      .MuiDialogContent-root {
        padding: 0 !important;
        margin-bottom: 15px;
        margin-top: 0;
        margin-bottom: 0;
      }

      .MuiTypography-h6 {
        color: $navyblue;
        font-size: 28px !important;
      }

      .MuiDialogContentText-root {
        color: RGB(var(--theme-custom-color)) !important;
        font-size: 15px !important;
      }

      .MuiDialogActions-root {
        flex-direction: column;
        flex-wrap: wrap;

        .MuiButton-root {
          min-width: 85%;
          font-family: $SF_ProDisplay;
          padding: 10px 8px;
          text-align: center;
        }
      }

      .MuiDialogActions-spacing > :not(:first-child) {
        margin-left: 0;
      }

      .MuiDialogActions-spacing {
        .btn-outline-white {
          border: solid 1px RGB(var(--theme-custom-color)) !important;
          margin-bottom: 5px;
        }
      }
    }
  }
}

.dialogExpired {
  padding: 0px 0px;
  height: 300px;
  .dialogPopupInv {
    background: var(--darker-lighten-theme-custom-color) !important;
    padding: 12px;
    .expiredDialogContent {
      background: white !important;
    }
  }
  .expired {
    padding: 25px;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: RGB(var(--theme-custom-color));
    margin-bottom: 10px;
    display: block;
    .iconImage {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 6rem;
    }
    .message {
      display: block;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
    .returnBtn {
      text-align: center;
      button {
        background: rgb(var(--theme-custom-color));
        border-radius: 4.6152px;
        width: 61%;
        border: 0;
        color: #ffffff;
        font-size: 16px;
        font-weight: normal;
        font-style: normal;
        min-height: 40px;
        margin: auto;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        cursor: pointer;
        position: relative;
      }
    }
  }
}
.dialogLanguage {
  padding: 30px 21px;
  .langCloseIcon {
    padding: 0 !important;
    width: auto;
    position: absolute;
    top: 0;
    z-index: 99999999;
    width: 28px;
    right: 0px;

    button {
      background: RGB(var(--theme-custom-color));
      padding: 4px;

      svg {
        fill: #fff;
        font-size: 20px;
      }

      &:hover {
        background: RGB(var(--theme-custom-color));
      }

      &:active {
        background: RGB(var(--theme-custom-color));
        color: $white;
        border: solid 0px RGB(var(--theme-custom-color));
      }

      &:focus,
      &.focus {
        color: #ffffff;
        background: RGB(var(--theme-custom-color));
        border-color: transparent;
      }
    }
  }

  .langSuggestion {
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: RGB(var(--theme-custom-color));
    margin-bottom: 15px;
    display: block;
  }

  .dropdownAccordion {
    // width: 70%;
    // margin: auto;
    width: 100%;
    margin: unset;

    .card-body {
      padding: 0;
      font-size: 12px;
      box-shadow: 0px 12.595px 15.114px rgba(0, 64, 255, 0.05),
        0px 12.595px 47.8611px rgba(0, 0, 0, 0.05);
      border-radius: 6.29751px;

      ul {
        list-style: none;
        text-align: left;
        padding: 0;
        text-transform: uppercase;
        font-weight: 700;

        li {
          padding: 14px;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid $grayBg;
          align-items: center;
          color: $grayCol !important;
          cursor: pointer;

          .tickIcon {
            position: relative;
            display: none;

            &:before {
              content: "";
              position: absolute;
              height: 5px;
              width: 12px;
              top: 0px;
              right: 3px;
              margin: -4px 0 0 -6px;
              border: 1px solid #fff;
              border-width: 0 0 1px 1px;
              background: none;
              border-radius: 0 !important;
              transform: rotate(-45deg);
              -moz-transform: rotate(-45deg);
              -webkit-transform: rotate(-45deg);
              -ms-transform: rotate(-45deg);
              -o-transform: rotate(-45deg);
              z-index: 1;
            }

            &:after {
              content: "";
              height: 18px;
              width: 18px;
              display: block;
              background: RGB(var(--theme-custom-color));
              border-radius: 50%;
              position: absolute;
              right: 0px;
              top: -9px;
            }
          }

          &.active {
            .tickIcon {
              display: block;
            }
          }
        }
      }

      .MuiFormControl-root {
        padding-bottom: 0;
        margin-bottom: 0 !important;
      }

      .MuiFormControlLabel-labelPlacementStart {
        margin-left: 0;
        margin-right: -11px;
        flex-direction: row-reverse;
        border-bottom: 1px solid $grayBg;
        margin-bottom: 0;
        width: 100%;
        justify-content: space-between;
      }

      .MuiTypography-body1 {
        font-weight: 700 !important;
        font-size: 12px !important;
        color: $grayCol !important;
        text-transform: uppercase;
        margin-left: 15px;
      }
    }

    .customRadio {
      display: flex;
      width: 100%;
      flex-wrap: wrap;

      .card {
        width: 33.33%;
        margin-bottom: 10px;
        flex-wrap: wrap;
        padding-right: 10px;
        box-shadow: 0 0 0;
        border: 0;

        .card-header {
          background-color: #fff !important;
          background: none !important;
          border: 1px solid #fff;
          box-shadow: 0 0 0 !important;
          padding: 7px 13px;
          flex-direction: column;
          border-bottom: 0;
          min-height: unset;
          cursor: pointer;
          min-height: 54px;

          .LangName {
            font-style: normal;
            font-weight: normal !important;
            font-size: 15px;
            line-height: 20px;
            text-transform: capitalize;
            color: $black-blue;
            width: 100% !important;
            margin-bottom: 0;
          }

          .LangCountry {
            font-style: normal !important;
            font-weight: normal;
            font-size: 13px;
            line-height: 18px;
            text-transform: capitalize;
            color: $black-blue;
            width: 100% !important;
            margin-bottom: 0;
          }

          &:hover {
            /* Blue / Tints / Pressed Secondary */
            background: var(--lighten-theme-custom-color) !important;
            /* Primary / Blue */
            border: 0.196481px solid RGB(var(--theme-custom-color));
            box-sizing: border-box;
            box-shadow: 0px 0.785924px 1.96481px rgba(0, 0, 0, 0.25) !important;
            border-radius: 1.96481px !important;
          }
        }

        &.activeShow {
          .card-header {
            /* Blue / Tints / Pressed Secondary */
            background: var(--lighten-theme-custom-color) !important;
            /* Primary / Blue */
            border: 0.196481px solid RGB(var(--theme-custom-color));
            box-sizing: border-box;
            box-shadow: 0px 0.785924px 1.96481px rgba(0, 0, 0, 0.25) !important;
            border-radius: 1.96481px !important;

            &:before {
              content: "";
              position: absolute;
              height: 4px;
              width: 7px;
              top: -1px;
              right: 9px;
              margin: 0;
              border: 1px solid #fff;
              border-width: 0 0 1px 1px;
              background: none;
              border-radius: 0 !important;
              transform: rotate(-45deg);
              -moz-transform: rotate(-45deg);
              -webkit-transform: rotate(-45deg);
              -ms-transform: rotate(-45deg);
              -o-transform: rotate(-45deg);
              z-index: 1;
            }

            &:after {
              content: "";
              height: 12px;
              width: 12px;
              display: block;
              background: RGB(var(--theme-custom-color));
              border-radius: 50%;
              position: absolute;
              right: 6px;
              top: -4px;
            }
          }
        }
      }
    }
  }

  .shippingCustomRadio {
    .accordion {
      .card {
        border: none;

        &.activeShow {
          border: none;

          .card-body {
            border: none;
          }
        }

        .card-header {
          // line-height: 42px;
          // padding: 0px 25px;
          background: #ffffff;
          box-shadow: 0px 12.595px 15.114px rgba(0, 64, 255, 0.05),
            0px 12.595px 47.8611px rgba(0, 0, 0, 0.05);
          border-radius: 6.29751px;
          font-size: 12px;
          text-transform: uppercase;
          text-align: left !important;
          font-weight: 700;
          padding: 0 18px;
          color: RGB(var(--theme-custom-color));
          display: flex;
          justify-content: space-between;

          span {
            font-weight: 700 !important;
            color: RGB(var(--theme-custom-color));
          }

          span:last-child {
            border-width: 6px;
            border-style: solid;
            border-right: 6px solid transparent;
            border-color: $grayCol transparent transparent;
            width: 0;
            height: 0;
          }

          &:after {
            content: none;
          }

          &:before {
            content: none;
          }
        }
      }
    }
  }
}

#privacyDialogContent {
  text-align: left;

  .see_more {
    cursor: pointer;
    text-decoration: underline;
  }
}

/*
    *
    *   FOOTER
    *
*/

.footerWrap {
  padding: 0;
  position: fixed;
  text-align: center;
  margin: auto;
  width: 100%;
  bottom: 10px;

  .container {
    max-width: 411px;
    margin: auto;
    padding: 0 15px;
    justify-content: center;
    display: flex;

    .footer-main {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      img {
        cursor: pointer;
      }

      ul {
        display: inline;
        border-left: 1px solid $light-grey;
        margin: 0 0 0 19px;
        padding: 0 0 0 12px;

        li {
          display: inline-block;
          list-style: none;

          span {
            font-style: normal;
            font-weight: 300;
            font-size: 10px;
            line-height: 12px;
            font-family: $SF_TextRegular;
            color: $mid-grey;
            margin-right: 19px;
            display: block;
          }

          &:last-child {
            span {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  &.footer-transition {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 1s, opacity 1s linear;
  }
}

/*
    *
    *   KEY FRAME
    *
*/

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
  }
}

@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(359deg);
  }
}

@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }

  to {
    -o-transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.toolTipParent{
  .tooltip-inner {
    background-color: #EBF5FF  !important;
    border-color: #EBF5FF !important;
    max-width: 250px;
    padding: 0px !important
  }
  .toolTipContent {
    .contentAlert{
      display: flex;
      box-shadow: none;
      
      .toolTipContentAlert{
        width: 45px;
        background-color: #a1c3e6;
        color: $white;
        border-color: rgb(235 245 255);
        border: 1px solid transparent;
        overflow-wrap: break-word;
        border-radius: 3px;
        border-radius: 4px;
        box-shadow: 0 3px 8px rgb(235 245 255 / 18%);
        transition: transform 220ms cubic-bezier(0.2, 0, 0, 1), opacity 220ms;
        transform: translate3d(0, 0, 0);
        font-size: 1.5rem;
        vertical-align: middle;
        line-height: 60px;
        text-align: center;
      }
      .alertMsg{
        margin: 7px 10px;
        // margin-inline-start: auto;
        .alertHeading {
          text-align: left;
          font-style: normal;
          font-weight: 600;
          color: #192631 !important;
        }
        .alertDescription {
          text-align: left;
          font-style: normal;
          font-weight: normal;
          font-size: 13.3197px;
          line-height: 18px;
          color: #192631 !important;
          flex: none;
          order: 1;
          flex-grow: 0;
        }
      }
    }
  }
}

.newToolTip {
  background: #fff;
  opacity: 1 !important;
  box-shadow: 0 0 0 !important;
  .tooltip-inner {
    max-width: 250px;
    padding: 8px 10px;
    // box-shadow: 0px 3.72405px 11.1722px rgba(0, 0, 0, 0.12),
    //   0px 11.1722px 26.0683px rgba(60, 66, 87, 0.08);
  }
  .toolTipContent {
    opacity: 1 !important;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: flex-end;
    letter-spacing: 0em;
    color: #143656;
    box-shadow: 0 0 0 !important;
    text-align: left;
    flex-direction: column;
  }
}
.toolTipButton {
  padding: 0 !important;
  width: 20px;
  height: 22px;
  margin: 0 0 0 10px !important;
  .MuiIconButton-label {
    color: transparent !important;
    svg {
      background-image: url(/assets/images/tooltip-icon.svg);
      background-repeat: no-repeat;
      width: 20px;
      height: 22px;
      background-size: cover;
    }
  }
}

.customRadio {
  .paymentMethod {
    .card {
      &.defaultCard{
        border: none !important;
      }
      .card-header {
        flex-wrap: wrap;
        max-height: unset !important;
        padding: 0;
        .paymentMethodHeader {
          width: 100%;
          align-items: center;
          justify-content: center;
          display: flex;
          padding: 12px 30px;
          height: 46px;
        }
        &.card-payment-protection {
          border: none !important;
          border-top: 1px solid rgba(0, 40, 100, 0.12) !important;
          // max-height: unset !important;
          min-height: 35px !important;
          background: $white !important;
          border-radius: 0 !important;
          width: 100%;
          padding: 0px 15px !important;
          font-size: 13.7224px !important;
          &:before {
            display: none !important;
          }
          &:after {
            display: none !important;
          }
          .payment-protection {
            display: flex;
            align-items: flex-end;
            padding: 11px 0;
            .iconImage {
              width: 15px;
              text-align: center;
              margin: auto;
              margin-right: 10px;
              margin-top: -2px;
              img {
                max-width: 45px;
                max-height: 43px;
                object-fit: cover;
              }
            }
            .protection {
              display: flex;
              align-items: center;
              font-style: normal;
              font-weight: normal;
              font-size: 13.7224px;
              line-height: 18px;
              text-align: center;
              letter-spacing: -0.02em;
              color: #192631;
            }
          }
        }
      }
    }
  }
}


.noEvents {
  pointer-events: none !important;
}
.header-fixed {
  position: fixed;
  width: 100%;
  z-index: 99;
  top: 0 !important;
  background: $white;
}
.header-padding {
  padding-top: 3.75rem;
  // padding-top: 63px;
  // padding-top: 85px;
}
.contentWrapperPadding{
  padding-top: 1.45rem;
}

.inline-items{
  display: flex;
  justify-content: space-between;
}
.inline-items > div{
    width: 48%;
}
.shipmentRadio{
    .card-header-disabled{
      opacity: 0.8;
      background: #de132a0d;
      cursor: not-allowed;
    }
}
.outline-danger{
   border: solid 1px $red !important;
}
.text-danger {
   color: $red !important;
   text-decoration: underline;
}
.enable-browser{
  margin-bottom: 10px;
  .fs-12{
    font-size: 26px;
  }
}


.alert_classes{
  margin-bottom: 15px;
  .alertCustom{
    border: 1px solid transparent;
    overflow-wrap: break-word;
    position: relative;
    /* padding: 0.75rem 1.25rem; */
    border-radius: 3px;
    border-radius: 4px;
    box-shadow: 0 3px 8px rgb(0 0 0 / 18%);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 8px;
    max-width: 100%;
    -webkit-transition: -webkit-transform 220ms cubic-bezier(0.2,0,0,1),opacity 220ms;
    -webkit-transition: transform 220ms cubic-bezier(0.2,0,0,1),opacity 220ms;
    transition: transform 220ms cubic-bezier(0.2,0,0,1),opacity 220ms;
    width: 360px;
    -webkit-transform: translate3d(0,0,0);
    -ms-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    font-size: 0.9375rem;
    &.default{
      color: RGB(var(--theme-custom-color));
      background: var(--lighten-theme-custom-color);
      background-color: var(--lighten-theme-custom-color);
      border-color: var(--lighten-theme-custom-color);
      .alertAction{
        background-color: RGB(var(--theme-custom-color))  !important;;
      }
    }
    &.success{
      color: #316100;
      background-color: #dff1cc;
      border-color: #d2ecb8;
      .alertAction{
        background-color: #316100  !important;;
      }
    }
    &.info{
      color: #a1c3e6;
      background-color:  #EBF5FF;
      border-color:  #EBF5FF;
      .alertAction{
        background-color:  #a1c3e6  !important;
      }
      span {
        font-size: 20px;
      }
    }
    &.error{
      color: #BF2600;
      background: #FEE9EA;
      border-color: #FEE9EA;
      .alertAction{
        background-color: $pink  !important;;
      }
    }
    &.warning{
      color: #f2994a;
      background-color: #fcf3cf;
      border-color: #fbeebc;
      .alertAction{
        background-color: #f2994a  !important;;
      }
    }
    .alertAction{ 
      background-color: var(--lighten-theme-custom-color);
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      color: #FFEBE6;
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      // padding-bottom: 8px;
      // padding-top: 8px;
      position: relative;
      overflow: hidden;
      text-align: center;
      width: 40px;
      /* vertical-align: middle; */
      display: flex;
      // margin: 0 auto;
      align-items: center;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      span{
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        color: #FFF;
        opacity: 1;
      }
    }
    .alertMsg{
      white-space: pre-wrap;
      margin: 15px;
      // margin-inline-start: auto;
      .alertHeading {
        text-align: left;
        font-style: normal;
        font-weight: 600;
        color: #192631 !important;
      }
      .alertDescription {
        text-align: left;
        font-style: normal;
        font-weight: normal;
        font-size: 13.3197px;
        line-height: 18px;
        color: #192631 !important;
        flex: none;
        order: 1;
        flex-grow: 0;
      }
    }
  }
}
.paymentActions{
  label {
    margin-bottom: 0px !important;
  }
  .saveDafaults{
  
  }
}
.mapLocationBtn{
  padding: 0px !important;
  i {
     margin-right: 5px;
  }
}
.mt-24{
  margin-top: 24px !important;
}

.myForm {
  &.addressForm{
    .addressForm-fields  >  div:not(:last-child) {
      margin-bottom: 9px;
    }
  }
}


.cvvTooltip {
  span {
    font-family: $SF_ProDisplay;
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    color: RGB(var(--theme-custom-color));
  }
  img {
    width: 35%;
  }
}

.statusContainer{
  padding-top: 30vh;
  text-align: center;
  width: 95%;
  max-width: 600px;
  margin:auto;
  .inProgress{
    color: rgb(var(--theme-custom-color)) !important;
  }
  .inProgressDivider {
    border-bottom-width: 0.15rem;
    border-color: rgb(var(--theme-custom-color));
    background-color: rgb(var(--theme-custom-color)) !important;
    margin-top: 30px;
    margin-bottom: 35px;
  }
  .inProgressText{
   color: $mid-grey
  } 
}

/*
    *
    *   MEDIA QUERIES
    *
*/

@media only screen and (max-width: 480px) {
  .receiptDiscountBox {
    .bigReceiptHeadingBox {
      .left {
        font-size: 17px !important;
      }
      .right {
        font-size: 17px !important;
      }
    }
  }

  .receiptSection {
    .receiptDiscounts {
      .receiptSub {
        .left {
          width: 62% !important;

          div p {
            width: 53% !important;
            margin-right: 5px;
          }

          div .discount-pricing {
            display: inherit;
          }
        }
      }
    }
  }
}

.accordion-card {
  .accordion-container-parent{
    width: 100%;
    height: 46px ;
    &.protection-enabled{
      height: 92px;
    }
    min-height: 0;
    position: relative;
    border: solid 1px $grayCol;
    border-radius: 4px;
    &.no-background{
      background-color: var(--lighten-theme-custom-color);
      color: $white;
      border: none;
      border-radius: 3px 3px 0 0 !important;
      margin-bottom: 0px;
    }
  }
}

.add-new-card{
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 12px 30px;
  height: 46px !important;
  min-height: 0;
  font-size: 1rem;
  font-weight: bold;
}

.my-vouchers-container{
  .dialog{
    max-height: 150px;
  }
}

.product-off-container{
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  .product-off{
    height: 13px;
    border-top-left-radius: 3px;
    color: $white;
    background: $redBg;
    padding: 0 0 0 3px;
    font-weight: 500;
    font-size: 8px;
    min-width: 24px;
    min-width: 42px
  }
  .product-triangle{
    border-top: 6px solid transparent;
    border-left: 8px solid $redBg;
    border-bottom: 6px solid transparent;
  }
}

@mixin text-overflow-hide {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.sale-header-off{
  max-width: 80px;
  &.cart-top{
    max-width: max-content;
    .campaign-sale-name{
      margin-right: 0;
    }
  }
  .campaign-sale-name{
    background: $redBg;
    border-radius: 5px;
    height: 13px;
    font-weight: 500;
    font-size: 8px;
    color: $white;
    margin-right: 10px;
    margin-bottom: 7px;
    @include text-overflow-hide;
    padding: 0 3px;
    text-align: center;
    word-break: break-all;
  }
}


//Dialog box
.middleDialog{
  .MuiBackdrop-root{
    background-color: rgba(0, 0, 0, 0.5) !important;
  }
  .MuiDialog-paperWidthSm {
    background: $white !important;
    color: $black-blue !important;
    margin: 0 20px !important;
    max-width: 500px !important;
    border-radius: 10px !important;
    max-height: 450px !important;
  }
  .MuiDialogTitle-root {
    padding: 25px 24px 0px !important;
  }
  .MuiDialog-scrollPaper {
    display: flex;
    height: 100vh !important;
    align-items: center !important;
  }
}

.theme-color{
  color: RGB(var(--theme-custom-color)) ;
}

.multi-phone-container{
  max-width: 320px;
  margin: auto; 
}

.rotate-180{
  transform: rotate(180deg);
}
.dropdown-methods{
  .outlineSelect {
    background: var(--lighten-theme-custom-color);
    border: 1px solid RGB(var(--theme-custom-color));
    border-radius: 4px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.04);
    padding: 10px;
    max-height: 47px;
    color: $black-blue;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    .list-item{
      gap: 15px;
      display: flex;
      align-items: center;
    }
  }

  .methods-listing-container{
    border: 1px solid $grayBg;
    border-radius: 4px;
    .list-item{
      &.selected-value{
        background: var(--lighten-theme-custom-color)
      }

      color: $black-blue;
      gap: 15px;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 16px;
    }
    .MuiListItem-gutters {
      padding-left: 8px;
      padding-right: 8px;
    }
    div:last-child {
      hr {
        display: none;
      }
    }
    .MuiList-padding {
      padding-top: 0px;
      padding-bottom: 0px;
  }
  }
}


.closeIconDialog{
  position: absolute;
  right: 13px;
  top: 19px;
  i{
    font-size: 14px;
    font-weight: bold;
  }
}

.right-order-num {
	font-size: 14px !important;
	line-height: 23px;
}
.address-btn {
  span{
    font-size: 22px;
  }
  padding: 1px;
  margin-bottom: 25px;
  border-radius: 0;
}

.post-order-msg{
	background: RGB(var(--lighten-theme-custom-color));
	border-width: 1px 1px 1px 4px;
	border-color: RGB(var(--theme-custom-color));
	border-style: solid;

	border-radius: 4px;
	margin: 20px 0;
	color: RGB(var(--theme-custom-color));
	
	padding: 20px 15px;
}

.banner-alert {
	background: #FFE8C3;
	margin-bottom: 20px;
	.alertCustom {
		border: 1px solid transparent;
		position: relative;
		border-radius: 4px;
		display: flex;
		max-width: 100%;
		width: 100%;

		.alertAction {
			border-top-left-radius: 4px;
			border-bottom-left-radius: 4px;
			background-color: #FBB94D;
			position: relative;
			width: 10%;
			span {
				position: absolute;
				left: 50%;
				top: 30%;
				-webkit-transform: translate(-50%, -50%);
				transform: translate(-50%, -50%);
				opacity: 1;
			}
		}
		.alertMsg {
			width: 90%;
			text-align: initial;
			padding: 20px 15px;
		}
	}
}

.review-name-email{
  text-align: initial;
  padding: 13px 0;
  h6{
    font-size: 17px;
    padding-bottom: 8px;
  }
  margin-bottom: 25px;
  border-bottom: solid 1px RGB(var(--theme-custom-color));
}

//Error msg
@mixin info-msg-text {
	font-size: 13px;
	text-align: initial;
	i {
		line-height: inherit;
		font-size: 15px;
		display: inline-block;
		transform: rotate(180deg) !important;
	}
}

.recheck-address-text {
  @include info-msg-text;
  color: $pink;
  margin-top: -10px;
}

.recheck-address-text {
  display: block;
}

.addressForm-fields:focus-within ~ .recheck-address-text {
  display: none;
}